import UserProfile from "src/models/UserProfile";
import CircleUserAvatar from "../CircleUserAvatar";
import styles from "./PopularAccount.module.scss";

interface PopularAccountProps {
  data: UserProfile;
  longName?: boolean;
  onClick?: (UserProfile) => void;
}

const PopularAccount = ({
  data,
  longName = false,
  onClick,
}: PopularAccountProps) => {
  const _handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  return (
    <div className={`${styles.accountContainer}`} onClick={_handleClick}>
      <div className="mb6">
        <CircleUserAvatar size={56} url={data.profilePhoto.small?.url} />
      </div>

      <span
        className={`${styles.accountFullname} body13 textBlack2`}
        style={
          longName
            ? { width: "100%", WebkitLineClamp: 2, lineClamp: 2 }
            : { width: "84px" }
        }
      >
        {!longName && data.fullname.length >= 11
          ? data.fullname.substring(0, 8) + "..."
          : data.fullname}
      </span>
    </div>
  );
};

export default PopularAccount;
