import { initializeApp } from 'firebase/app';

// Use your config values here.
const firebaseConfig = {
  apiKey: "AIzaSyB2araUb7GVHpbICsUeEsPfr9ve5XyR68Y",
  authDomain: "mb-inhandao-prod.firebaseapp.com",
  projectId: "mb-inhandao-prod",
  storageBucket: "mb-inhandao-prod.appspot.com",
  messagingSenderId: "715353110231",
  appId: "1:715353110231:web:7f4aa538c818a1549d6292",
  measurementId: "G-31P0LTMQ5P"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
