import styles from "./About.module.scss";
import Image from "src/components/Image";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import BlurToolbar from "src/components/BlurToolbar";
import thiennguyenBannerImg from "src/assets/images/thiennguyen-banner.png";
import kolDemoImg from "src/assets/images/kol-demo.png";
import Utils from "src/utils/Utils";
import { getStatisticNumber } from "src/api";
import { useCallback, useEffect, useState } from "react";
import AppStatistic from "src/models/AppStatistic";
import CountUp from "react-countup";
import { THIENNGUYEN_APP_DEEPLINK } from "src/Constants";
import useConfig from "src/hooks/UseConfig";

const About = () => {
  const { t } = useTranslation();
  const [appStatistic, setAppStatistic] = useState<AppStatistic>();
  const config = useConfig();
  const _handleExplore = () => {
    console.log("_handleExplore");
    Utils.openBrowser(config?.deepLinkMiniapp || THIENNGUYEN_APP_DEEPLINK);
  };

  const _getStatistic = useCallback(async () => {
    try {
      const statisticRes = await getStatisticNumber();
      console.log("statisticRes", statisticRes);
      setAppStatistic(statisticRes.data);
    } catch (err) {
      console.log("_getStatistic err", err);
    }
  }, []);

  useEffect(() => {
    _getStatistic();
  }, []);

  return (
    <div className="screenContainer">
      <BlurToolbar />
      <div className="blurToolbarSpacing" />
      <div className={styles.content}>
        <div className={styles.bannerSpacer} />
        <div className={styles.bannerContainer}>
          <Image src={thiennguyenBannerImg} className={styles.bannerImg} />
        </div>
        <div className={styles.infoContainer}>
          <div className="rowCenter">
            <Image src={kolDemoImg} className={styles.kolDemoImg} />
          </div>
          <div className="rowSpaceBetweenAlignStart">
            <div className={"columnCenter mr10"}>
              {!!appStatistic?.numberUser ? (
                <CountUp
                  start={0}
                  end={appStatistic?.numberUser}
                  duration={1.5}
                  formattingFn={Utils.formatMoney}
                  className="title22 textPrimary mb8 tabularNum"
                />
              ) : (
                <div className="title22 textPrimary mb8 opacity0">0</div>
              )}
              <div className="body12 textGray text-center">
                {t("donate-account")}
              </div>
            </div>
            <div className={"columnCenter"}>
              {!!appStatistic?.totalTransactionAmount ? (
                <CountUp
                  start={0}
                  end={appStatistic?.totalTransactionAmount}
                  duration={1.5}
                  formattingFn={Utils.formatMoney}
                  className="title22 textPrimary mb8 tabularNum"
                />
              ) : (
                <div className="title22 textPrimary mb8 opacity0">0</div>
              )}

              <div className="body12 textGray text-center">
                {t("achieve-money")}
              </div>
            </div>
          </div>
        </div>
        <div className="bottomButtonSpacer" />
        <div className="bottomButtonBlockBlur">
          <Button
            variant="primary"
            className="fullWidth"
            onClick={_handleExplore}
          >
            {t("explore-now")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default About;
