import styles from "./KolProfile.module.scss";
import Image from "src/components/Image";
import { useTranslation } from "react-i18next";
import BlurToolbar from "src/components/BlurToolbar";
import CircleUserAvatar from "src/components/CircleUserAvatar";
import thiennguyenImg from "src/assets/images/thiennguyen.png";
import facebookImg from "src/assets/images/facebook.png";
import instaImg from "src/assets/images/instagram.png";
import quotesImg from "src/assets/images/quotes.png";
import BankAccountItem from "src/components/BankAccountItem";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { getUserProfile } from "src/api";
import UiService from "src/services/UiService";
import { useCallback, useEffect, useState } from "react";
import UserProfile from "src/models/UserProfile";
import { SOCIAL_INFO_TYPE } from "src/Constants";
import Utils from "src/utils/Utils";
import { toast } from "react-toastify";
import infoWhiteImg from "src/assets/images/info-white.png";
import ShowMoreText from "react-show-more-text";
import checkedPrimaryImg from "src/assets/images/checked-primary.png";
import PullToRefresh from "react-simple-pull-to-refresh";
import Loading from "src/components/Loading";

const KolProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const userId = queryParams?.userId;
  const donateTargetId = queryParams?.donateTargetId;
  const [kolProfile, setKolProfile] = useState<UserProfile>();

  const _loadKolProfile = useCallback(
    async (refreshing: boolean = false) => {
      try {
        if (!refreshing) {
          UiService.showLoading();
        }
        const kolProfileRes = await getUserProfile(userId);
        console.log("kolProfileRes", kolProfileRes);
        if (!refreshing) {
          UiService.hideLoading();
        }
        setKolProfile(kolProfileRes?.data);
      } catch (err: any) {
        console.log("kol profile", err?.response);
        if (!refreshing) {
          UiService.hideLoading();
        }
        if (err?.response?.data?.code === 400) {
          history.replace("/not-found");
        }
      }
    },
    [userId, history]
  );

  useEffect(() => {
    _loadKolProfile();
  }, []);

  const _handleRefresh = async () => {
    await _loadKolProfile(true);
  };

  const _showSocialInfo = (infoType: SOCIAL_INFO_TYPE) => {
    let socialUrl: string | null | undefined = null;
    if (infoType === SOCIAL_INFO_TYPE.THIENNGUYEN) {
      socialUrl = kolProfile?.permalink;
    } else if (infoType === SOCIAL_INFO_TYPE.FACEBOOK) {
      socialUrl = kolProfile?.facebookUrl;
    } else if (infoType === SOCIAL_INFO_TYPE.INSTAGRAM) {
      socialUrl = kolProfile?.instagramUrl;
    }
    console.log("socialUrl", socialUrl);
    if (socialUrl) {
      Utils.openBrowser(socialUrl);
    } else {
      toast(
        <div className={styles.warningToast}>
          <Image className={styles.toastIcon} src={infoWhiteImg} />
          <span className="textOnPrimary body13">
            {t("account-not-setup-this-info-yet")}
          </span>
        </div>,
        {
          closeButton: false,
          hideProgressBar: true,
          autoClose: 2000,
        }
      );
    }
  };

  return (
    <div className="screenContainer">
      <BlurToolbar />
      <div className="toolbarContainerTop" />

      <PullToRefresh
        onRefresh={_handleRefresh}
        refreshingContent={<Loading containerClassName="columnCenter" />}
        pullingContent=""
      >
        <div className={styles.scrollContainer}>
          <div className={styles.topSpacer} />
          <div className={styles.avatarContainer}>
            <div className={styles.avatarStar}>
              <CircleUserAvatar
                size={119}
                url={kolProfile?.profilePhoto.small?.url}
                gradientBorder={true}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.profileBox}>
              <div className="title18 textBlack4 mb2 text-center breakWord">
                <span className="verticalMiddle">{kolProfile?.fullname}</span>
                {!!kolProfile?.fullname && (
                  <Image
                    className={"verifiedCheckSmall"}
                    src={checkedPrimaryImg}
                  />
                )}
              </div>
              <div className="body14 textGray3 mb14 breakWord text-center">
                {Utils.getDisplayUserName(kolProfile?.username)}
              </div>
              <div className="rowCenter mb16">
                <div
                  className={`${styles.socialIconContainer} mr14`}
                  onClick={() => _showSocialInfo(SOCIAL_INFO_TYPE.THIENNGUYEN)}
                >
                  <Image src={thiennguyenImg} className={styles.socialIcon} />
                </div>
                <div
                  className={`${styles.socialIconContainer} mr14`}
                  onClick={() => _showSocialInfo(SOCIAL_INFO_TYPE.FACEBOOK)}
                >
                  <Image src={facebookImg} className={styles.socialIcon} />
                </div>
                <div
                  className={styles.socialIconContainer}
                  onClick={() => _showSocialInfo(SOCIAL_INFO_TYPE.INSTAGRAM)}
                >
                  <Image src={instaImg} className={styles.socialIcon} />
                </div>
              </div>
              {!!kolProfile?.biography && (
                <div className="rowCenter mb24">
                  <div className={styles.primaryLineLeft} />
                  <Image src={quotesImg} className={styles.quotesImg} />
                  <div className={styles.primaryLineRight} />
                </div>
              )}
              {!!kolProfile?.biography && (
                <ShowMoreText
                  lines={5}
                  more={t("view-more")}
                  less={t("collapse")}
                  className="body14 textGray3 text-center"
                  anchorClass="body14 w500 textGray4 viewMoreAnchor"
                >
                  {kolProfile?.biography}
                </ShowMoreText>
              )}
            </div>
            <div className="title16 textBlack2 mb14">{t("bank-account")}</div>
            {!!kolProfile?.defaultBankAccount && (
              <div className="mb16">
                <BankAccountItem data={kolProfile?.defaultBankAccount} />
              </div>
            )}
          </div>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default KolProfile;
