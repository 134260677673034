import React, { Suspense, useEffect, useRef } from "react";
import "./styles/globals.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/bootstrap.scss";
import "./styles/DatePicker.scss";
import GlobalPopupConfirm, {
  GlobalPopupConfirmRef,
} from "./components/GlobalPopupConfirm";
import UiService from "./services/UiService";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import LoadingModal, { LoadingModalRef } from "src/components/LoadingModal";
import { Worker } from "@react-pdf-viewer/core";
import ScrollToTop from "./components/ScrollToTop";
import { PRELOAD_IMAGES } from "./Constants";
import { UserInfoProvider } from "./contexts/UserInfoContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useExtendSession from "./hooks/UseExtendSession";
import Analytics from './firebase/Analytics';

function App() {
  UiService.popupInstance = useRef<GlobalPopupConfirmRef>();
  UiService.loadingInstance = useRef<LoadingModalRef>();
  useExtendSession();

  const _renderLoading = () => {
    return (
      <div className="columnCenter mt56">
        <div className="loadingSmall-spinner-rolling">
          <div className="loadingSmall">
            <div></div>
          </div>
        </div>
      </div>
    );
  };

  const _preloadImage = () => {
    PRELOAD_IMAGES.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });
  };

  useEffect(() => {
    _preloadImage();
    Analytics.logEvent()
  }, []);

  return (
    <UserInfoProvider>
      <Worker workerUrl={"https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"}>
        <Router>
          <ScrollToTop />
          <ToastContainer />
          <Suspense fallback={_renderLoading()}>
            <GlobalPopupConfirm ref={UiService.popupInstance} />
            <LoadingModal ref={UiService.loadingInstance} />
            <AppRoutes />
          </Suspense>
        </Router>
      </Worker>
    </UserInfoProvider>
  );
}

export default App;
