import { useTranslation } from "react-i18next";
import styles from "./PopularTargets.module.scss";
import DonateTargetItem from "src/components/DonateTargetItem";
import DonateTarget from "src/models/DonateTarget";
import { useHistory } from "react-router-dom";

interface PopularTargetsProps {
  data?: DonateTarget[];
}

const PopularTargets = ({ data }: PopularTargetsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const showAllHandler = () => {
    history.push("/search-target");
  };

  const _renderDonateTargetItem = (item: DonateTarget, index: number) => {
    return (
      <div className="mb12" key={item.id + "-" + index}>
        <DonateTargetItem data={item} />
      </div>
    );
  };

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <div className={`marginHori16`}>
      <div className="rowSpaceBetween mb14">
        <div className={`body16 w700`}>
          <div>{t("popular-target")}</div>
        </div>
        <div
          className={`${styles.showAllText} body14`}
          onClick={showAllHandler}
        >
          {t("show-all")}
        </div>
      </div>

      <div className={``}>{data?.map(_renderDonateTargetItem)}</div>

      <div></div>
    </div>
  );
};

export default PopularTargets;
