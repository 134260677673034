import CircleUserAvatar from "../CircleUserAvatar";
import styles from "./RecentDonationItem.module.scss";
import Utils from "src/utils/Utils";
import { useTranslation } from "react-i18next";
import Donation from "src/models/Donation";
import { format } from "timeago.js";
import * as timeago from "timeago.js";
import vi from "timeago.js/lib/lang/vi";
timeago.register("vi", vi);
interface RecentDonationItemProps {
  data: Donation;
}
const RecentDonationItem = ({ data }: RecentDonationItemProps) => {
  const { t } = useTranslation();

  const _renderDonator = () => {
    return (
      <span>{!!data.user ? data.user.fullname : t("incognito-donator")}</span>
    );
  };

  return (
    <div className={styles.itemContainer}>
      <div className="mr12">
        <CircleUserAvatar
          size={46}
          incognito={!data.user}
          url={data?.user?.profilePhoto?.small?.url}
        />
      </div>
      <div className="flex1">
        <div className="body14 textBlack2 mb4 breakWord">
          <span className="textPrimary w600">
            +{Utils.formatMoney(data.amount)}
          </span>{" "}
          {t("from")} {_renderDonator()}
        </div>
        {data.hiddenDonateMessage ? (
          <div className="body14 textGray3 mb4 breakWord">
            {t("message-hiden")}
          </div>
        ) : !!data.message ? (
          <div className="body14 textBlack2 mb4 breakWord">{`"${data.message}"`}</div>
        ) : (
          <></>
        )}
        <div className="body12 textGray4">{format(data.createdTime, "vi")}</div>
      </div>
    </div>
  );
};
export default RecentDonationItem;
