import styles from "./SearchAccount.module.scss";
import { useState, useEffect, useCallback } from "react";
import HeaderBottomBar from "src/components/HeartBottomBar";
import PopularAccount from "src/components/PopularAccounts/PopularAccount";
import UserProfile from "src/models/UserProfile";
import { searchAccount } from "src/api";
import Paging from "src/models/Paging";
import debounce from "lodash/debounce";
import Utils from "src/utils/Utils";
import NoData from "src/components/NoData";
import Loading from "src/components/Loading";
import { useHistory } from "react-router-dom";
import SearchToolbar from "src/components/SearchToolbar";
import useLocalStorage from "src/hooks/UseLocalStorage";
import PullToRefresh from "react-simple-pull-to-refresh";
import Tabs from "src/components/Tabs";
import { useTranslation } from "react-i18next";

const SearchAccount = () => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const history = useHistory();
  const [keyword, setKeyword] = useLocalStorage<string>(
    "searchAccountKeyword",
    "",
    history.action !== "POP"
  );
  const [listUser, setListUser] = useState<Paging<UserProfile>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [showHeartBar, setShowHeartBar] = useState<boolean>(true);
  const [searchState, setSearchState] = useLocalStorage<boolean>(
    "searchAccountState",
    false,
    history.action !== "POP"
  );

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = useCallback(
    async (
      nextPageId: string | null = null,
      keyword: string | null = null,
      firstLoad: boolean | null = true,
      refreshing: boolean | undefined = false,
      tabIndex: number = activeTabIndex
    ) => {
      try {
        if (!refreshing) {
          if (firstLoad) {
            setLoading(true);
          } else {
            setLoadingMore(true);
          }
        }
        const listUserRes = await searchAccount(
          21,
          nextPageId,
          keyword,
          getTabIdByTabIndex(tabIndex)
        );
        console.log("search user res: ", listUserRes);
        if (!nextPageId) {
          setListUser(listUserRes?.data);
        } else {
          setListUser({
            list: [
              ...(listUser?.list || []),
              ...(listUserRes?.data?.list || []),
            ],
            nextPageId: listUserRes?.data?.nextPageId,
          });
        }
        setLoading(false);
        setLoadingMore(false);
        console.log("listUser:", listUserRes);
      } catch (err) {
        setLoading(false);
        setLoadingMore(false);
        console.log("Search user err: ", err);
      }
    },
    [listUser, setListUser, activeTabIndex]
  );

  const _loadMore = () => {
    console.log("_loadMore", listUser);
    if (
      !listUser?.nextPageId ||
      !listUser.list ||
      listUser.list.length === 0 ||
      loading ||
      loadingMore
    ) {
      return;
    }

    _loadData(listUser.nextPageId, keyword, false);
  };

  const _handleRefresh = async () => {
    if (loading || loadingMore) return;
    await _loadData(null, keyword, true, true);
  };

  const _handleScroll = (e) => {
    if (Utils.isScrollToEnd(e)) {
      _loadMore();
    }
  };

  const _search = debounce(
    (nextPageId?: string | null, keyword?: string | null) => {
      _loadData(nextPageId, keyword);
    },
    300
  );

  const _handleChangeSearchState = (value: boolean) => {
    setSearchState(value);
  };

  const _handleChangeKeyword = (event) => {
    const newKeyword = event.target.value;
    setKeyword(newKeyword);
    setLoading(true);
    _search(null, newKeyword);
    console.log(keyword);
  };

  const _handleClearKeyword = () => {
    setKeyword("");
    _search(null, null);
  };

  const _handleClickItem = (user: UserProfile) => {
    history.push(`/search-target?userId=${user.id}&fullname=${user.fullname}`);
  };

  const _renderGridItems = (item: UserProfile, index: number) => {
    return (
      <div
        className={`${styles.gridItems} mb22`}
        onClick={() => _handleClickItem(item)}
        key={item.id}
      >
        <PopularAccount data={item} longName={true} />
      </div>
    );
  };

  const _renderData = () => {
    return loading ? (
      <Loading />
    ) : (
      <PullToRefresh
        onRefresh={_handleRefresh}
        refreshingContent={<Loading containerClassName="columnCenter" />}
        pullingContent=""
      >
        {listUser?.list.length === 0 ? (
          <NoData searching={true} />
        ) : (
          <div onScroll={_handleScroll} className={styles.dataContainer}>
            <div className={`${styles.gridContainer}`}>
              {listUser?.list.map(_renderGridItems)}
            </div>
            {loadingMore && <Loading containerClassName={"columnCenter"} />}
          </div>
        )}
      </PullToRefresh>
    );
  };

  const _handleFocus = () => {
    setShowHeartBar(false);
  };

  const _handleBlur = () => {
    setShowHeartBar(true);
  };

  const handleChangeTab = async (index: number) => {
    if (index === activeTabIndex) return;
    setActiveTabIndex(index);
    _loadData(null, keyword, true, true, index);
  };

  // Lấy id của tab dự theo index
  const getTabIdByTabIndex = (index: number) => {
    // Với tabindex = 0 -> Cá nhân
    // Với tabindex = 1 -> Tổ chức
    // params to server, Cá nhân: 1, Tổ chức: 2
    if (index === 0) return 1;
    if (index === 1) return 2;
    return 1;
  };

  return (
    <div className="screenContainer">
      <SearchToolbar
        searchState={searchState}
        onChangeSearchState={_handleChangeSearchState}
        title={"popular-account"}
        placeHolder={"find-account"}
        keyword={keyword}
        onClearInput={_handleClearKeyword}
        onChangeKeyword={_handleChangeKeyword}
        onFocusInput={_handleFocus}
        onBlurInput={_handleBlur}
        inputMaxLength={50}
      />
      <div className="marginHori16">
        <Tabs
          data={[t("individuals"), t("businesses")]}
          selectedTabIndex={activeTabIndex}
          onChangeTab={handleChangeTab}
        />
        {_renderData()}
      </div>
      {showHeartBar && <HeaderBottomBar />}
    </div>
  );
};

export default SearchAccount;
