import styles from "./BankAccountItem.module.scss";
import mbLogoImg from "src/assets/images/mb-logo.png";
import Image from "src/components/Image";
import BankAccount from "src/models/BankAccount";
import { useTranslation } from "react-i18next";
interface BankAccountItemProps {
  data?: BankAccount;
}
const BankAccountItem = ({ data }: BankAccountItemProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className="mr12">
        <div className={styles.mbLogoContainer}>
          <Image src={mbLogoImg} className={styles.mbLogo} />
        </div>
      </div>
      <div className="flex1">
        <div className="title16 textBlack2 mb2">{data?.accountName}</div>
        <div className="body14 textGray3">
          {t("at")} {data?.bankName}
        </div>
      </div>
      <div className="subTitle16 textPrimary ml8">{data?.accountNo}</div>
    </div>
  );
};
export default BankAccountItem;
