import styles from "./CircleUserAvatar.module.scss";
import Image from "src/components/Image";
import { useMemo } from "react";
import Utils from "src/utils/Utils";

interface AvatarProps {
  size: number;
  innerContainerClassName?: string;
  url?: string;
  incognito?: boolean;
  gradientBorder?: boolean;
}
const CircleUserAvatar = ({
  url,
  size,
  innerContainerClassName,
  incognito,
  gradientBorder = false,
}: AvatarProps) => {
  const incognitoUserImg = useMemo(() => {
    return Utils.getIncognotoUserAvatarSrc();
  }, []);
  return (
    <div
      className={
        gradientBorder ? styles.gradientAvatarContainer : styles.avatarContainer
      }
    >
      {gradientBorder ? (
        <div
          className={`${styles.whiteContainer}${
            innerContainerClassName ? " " + innerContainerClassName : ""
          } `}
        >
          <Image
            style={{ width: size, height: size, objectFit: "cover" }}
            className={`${styles.avatar}`}
            src={incognito ? incognitoUserImg : url}
          />
        </div>
      ) : (
        <Image
          style={{ width: size, height: size, objectFit: "cover" }}
          className={`${styles.avatar}`}
          src={incognito ? incognitoUserImg : url}
        />
      )}
    </div>
  );
};

export default CircleUserAvatar;
