import React, {useCallback, useEffect, useState} from "react";
import Toolbar from "src/components/Toolbar";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import styles from "./Home.module.scss";
import Image from "src/components/Image";
import searchImg from "src/assets/images/search.svg";
import CircleUserAvatar from "src/components/CircleUserAvatar";
import Slider from "react-slick";
import PopularAccounts from "src/components/PopularAccounts";
import PopularTargets from "src/components/PopularTargets";
import CarouselIndicator from "src/components/CarouselIndicator";
import Utils from "src/utils/Utils";
import {
  getBanner, getDonateRecent,
  getHomeDonateTarget,
  getPopularUser, logger,
  logout, markOpenPopup,
} from "src/api";
import useLocalStorage from "src/hooks/UseLocalStorage";
import Banner from "src/models/Banner";
import HeaderBottomBar from "src/components/HeartBottomBar";
import DonateTarget from "src/models/DonateTarget";
import Paging from "src/models/Paging";
import UserProfile from "src/models/UserProfile";
import useUserInfo from "src/hooks/UseUserInfo";
import useConfig from "src/hooks/UseConfig";
import Loading from "src/components/Loading";
import PullToRefresh from "react-simple-pull-to-refresh";
import homeNoAccountTargetImg from "src/assets/images/home-no-account-target.png";
import UserInfoService from "src/services/UserInfoService";
import UiService from "src/services/UiService";
import qs from "qs";
import Dialog from "@mui/material/Dialog";
import logoImg from "../../assets/images/logo.png";
import groupImg from "../../assets/images/group.png";
import DialogContentText from "@mui/material/DialogContentText";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useConfig();
  const [activeImageIndex, setActiveImageIndex] = useLocalStorage<number>(
    "homeBannerIndex",
    0,
    history.action !== "POP"
  );
  const [banners, setBanners] = useLocalStorage<Banner[]>("banners", []);
  const [homeDonateTarget, setHomeDonateTarget] =
    useLocalStorage<Paging<DonateTarget>>("homeDonateTarget");
  const [popularUser, setPopularUser] =
    useLocalStorage<UserProfile[]>("homePopularUser");
  const [donateRecent, setDonateRecent] = useState<DonateTarget>();
  const [open, setOpen] = React.useState(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [loadingDonateTarget, setLoadingDonateTarget] = useState<boolean>(true);
  const [loadingPopularUser, setLoadingPopularUser] = useState<boolean>(true);

  const userInfo = useUserInfo();

  const _loadBanner = useCallback(async () => {
    try {
      const bannerRes = await getBanner();
      console.log("bannerRes", bannerRes);
      setBanners(bannerRes?.data?.list);
    } catch (err) {
      console.log("Load banner err", err);
    }
  }, [setBanners]);

  const _loadPopularUser = useCallback(async () => {
    try {
      setLoadingPopularUser(true);
      const popularUserRes = await getPopularUser();
      console.log("popularUserRes", popularUserRes);
      setPopularUser(popularUserRes?.data);
      setLoadingPopularUser(false);
    } catch (err) {
      setLoadingPopularUser(false);
      console.log("Load popular user err", err);
    }
  }, []);

  const _loadDonateRecent = useCallback(async () => {
    try {
      const donateRecentRes = await getDonateRecent();
      console.log("donateRecentRes", donateRecentRes);
      if (donateRecentRes.data) {
        setOpen(!donateRecentRes.data.showPopup);
        setDonateRecent(donateRecentRes.data);
        logger(JSON.stringify({
          LOG_DONATE_RECENT: {
            resp: donateRecentRes.data
          }
        }));
      } else {
        setOpen(false);
      }
    } catch (err) {
      console.log("Load donate recent err", err);
    }
  }, []);

  const _loadHomeDonateTarget = useCallback(
    async (
      nextPageId: string | null = null,
      hasOnHome: boolean | null = null
    ) => {
      try {
        setLoadingDonateTarget(true);
        if (nextPageId) {
          setLoadingMore(true);
        }
        const homeDonateTargetRes = await getHomeDonateTarget(
          nextPageId,
          hasOnHome
        );
        console.log("homeDonateTargetRes", homeDonateTargetRes);
        setLoadingMore(false);
        setLoadingDonateTarget(false);
        if (!nextPageId) {
          setHomeDonateTarget(homeDonateTargetRes.data);
        } else {
          setHomeDonateTarget({
            nextPageId: homeDonateTargetRes?.data?.nextPageId,
            list: [
              ...(homeDonateTarget?.list || []),
              ...(homeDonateTargetRes?.data?.list || []),
            ],
          });
        }
      } catch (err) {
        setLoadingMore(false);
        setLoadingDonateTarget(false);
        console.log("Load Home DonateTarget err", err);
      }
    },
    [homeDonateTarget, setHomeDonateTarget]
  );

  const _load = useCallback(async () => {
    await Promise.all([
      _loadBanner(),
      _loadHomeDonateTarget(),
      _loadPopularUser(),
      _loadDonateRecent(),
    ]);
  }, [_loadBanner, _loadHomeDonateTarget, _loadPopularUser]);

  const _checkDeepLink = () => {
    console.log("donateTargetId", queryParams.get("donateTargetId"));
    if (queryParams.has("donateTargetId")) {
      const donateTargetId = queryParams.get("donateTargetId");
      queryParams.delete("donateTargetId");
      history.replace({
        search: queryParams.toString(),
      });
      history.push(`/donate-target?id=${donateTargetId}`);
    }
  };

  useEffect(() => {
    _load();
    _checkDeepLink();
  }, []);

  const _handleRefresh = async () => {
    await _load();
  };

  const _onClickSearchButton = () => {
    history.push("/search-target", "home");
  };
  const _onClickSliderItems = (banner: Banner) => {
    logger('navigationType: ' + banner.navigationType + ',banner.name:' + banner.name);
    if (banner.navigationType === 'EXTERNAL_BROWSER_LINK') {
      if (banner.name === 'HiGreen') {
        Utils.openBrowser(
          banner.link +
            '?token=' +
            sessionStorage.getItem('access_token') +
            '&refresh_token=' +
            sessionStorage.getItem('refresh_token') +
            '&expire_at=' +
            sessionStorage.getItem('expire_at')
        );
      }
      else{
        Utils.openBrowser(
          banner.link || "https://thiennguyen.app/"
        );
      }
    } else {
      history.push(`/banner-detail?id=${banner.id}`, banner);
    }
  };

  const _renderBannerItem = (item: Banner, index: number) => {
    return (
      <Image
        key={item.id}
        src={item.photo}
        className={styles.image}
        onClick={() => _onClickSliderItems(item)}
      />
    );
  };

  const _renderSlider = () => {
    const numOfImages = banners.length;
    return (
      <div className="mb24">
        <Slider
          {...{
            className: "slider variable-width center",
            centerMode: true,
            dots: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "11px 0px",
            infinite: true,
            dotsClass: "sliderDots",
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            initialSlide:
              activeImageIndex > numOfImages - 1 ? 0 : activeImageIndex,
            beforeChange: (oldIndex, newIndex) => {
              setActiveImageIndex(newIndex);
            },
            onSwipe: (e) => {
              console.log("On swipe", e);
              if (e === "left") {
                if (activeImageIndex < numOfImages - 1) {
                  setActiveImageIndex(activeImageIndex + 1);
                } else {
                  setActiveImageIndex(0);
                }
              }
              if (e === "right") {
                if (activeImageIndex > 0) {
                  setActiveImageIndex(activeImageIndex - 1);
                } else {
                  setActiveImageIndex(numOfImages - 1);
                }
              }
            },

            appendDots: () => {
              return (
                <div className="mt16">
                  <CarouselIndicator
                    length={numOfImages}
                    activeIndex={
                      activeImageIndex > numOfImages - 1 ? 0 : activeImageIndex
                    }
                  />
                </div>
              );
            },
          }}
        >
          {banners.map(_renderBannerItem)}
        </Slider>
      </div>
    );
  };

  const _handleBack = async () => {
    try {
      UiService.showLoading();
      const logoutRes = await logout();
      console.log("logoutRes", logoutRes);
    } catch (err) {
      console.log("logout err", err);
    } finally {
      UiService.hideLoading();
      UserInfoService.clearInfo();
      window.localStorage.removeItem("homeBannerIndex");
      Utils.closeWebview();
    }
  };

  const _loadMore = () => {
    if (
      !homeDonateTarget?.nextPageId ||
      !homeDonateTarget.list ||
      homeDonateTarget.list.length === 0 ||
      loadingMore
    ) {
      return;
    }

    _loadHomeDonateTarget(
      homeDonateTarget?.nextPageId,
      homeDonateTarget.list[0].hasOnhome
    );
  };

  const _handleScroll = (e) => {
    if (Utils.isScrollToEnd(e)) {
      _loadMore();
    }
  };

  const handleClose = () => {
    setOpen(false);
    _markAsRead();
  };

  const _markAsRead = async () => {
    try {
      if (donateRecent && donateRecent.id) {
        const markOpenPopupRes = await markOpenPopup(donateRecent.id);
        logger(JSON.stringify({
          LOG_MARK_READ_POPUP: {
            donateRecentId: donateRecent.id,
            resp: markOpenPopupRes?.data
          }
        }));
        console.log("markOpenPopupRes", markOpenPopupRes);
      }
    } catch (err) {
      console.log("Mark open popup err", err);
    }
  };

  const _renderPopup = () => {
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div id={"popup-thanks"} className={styles.popupMainContent}>
          <Image src={logoImg} className={styles.logoImg}/>
          <Image src={groupImg} className={styles.groupImg}/>
          <div className={styles.bannerPopup}>
            <div className={styles.bannerPopupRound1}>
              <div className={styles.bannerPopupRound2}>
                <img src={donateRecent?.photo?.large?.url} className={styles.bannerPopupImg} alt=""/>
              </div>
            </div>
          </div>
          <DialogContentText className={styles.dialogDesc} id="alert-dialog-slide-description">
            <div className={styles.txtThank}>Cảm ơn</div>
            <div className={styles.nameDonator}>{UserInfoService.getUserInfo()?.fullname}</div>
            <div className={styles.txtDesc}>
              Cảm ơn bạn đã ủng hộ cho chiến dịch:
            </div>
            <div className={styles.txtDesc1}>
              “{donateRecent?.name}” do {donateRecent?.owner?.fullname} điều hành qua App Thiện nguyện
            </div>
          </DialogContentText>
        </div>
      </Dialog>
    )
  }

  const _renderNoTarget = () => {
    if (!loadingPopularUser && (!popularUser || popularUser.length === 0)) {
      return (
        <div className="columnCenter mt56 ph30">
          <Image
            src={homeNoAccountTargetImg}
            className={styles.homeNoAccountTargetImg}
          />
          <div className="subTitle13 textBlack2 mb4 text-center">
            {t("no-popular-user-and-target")}
          </div>
          <div className="body13 textGray3 text-center">
            {t("no-pupular-target-detail")}
          </div>
        </div>
      );
    }
    return (
      <div className="columnCenter mt90 ph36">
        <div className="subTitle13 textBlack2 mb4 text-center">
          {t("no-popular-target")}
        </div>
        <div className="body13 textGray3 text-center">
          {t("no-pupular-target-detail")}
        </div>
      </div>
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("app-name")} onBack={_handleBack}/>
      <PullToRefresh
        onRefresh={_handleRefresh}
        refreshingContent={<Loading containerClassName="columnCenter"/>}
        pullingContent=""
      >
        <div
          className="screenScrollContainer refreshContent"
          onScroll={_handleScroll}
        >
          <div className={styles.header}>
            <div className={`mr10`}>
              <CircleUserAvatar
                size={46}
                url={userInfo?.profilePhoto?.small?.url}
              />
            </div>
            <div className={styles.headerContent}>
              <div className={`title15 textBlack3 mb5 lineHeight22`}>
                {t("hello")}&nbsp;
                {userInfo?.fullname}
              </div>
              <div className={"body13 textGray"}>
                {!!userInfo?.nbDonation && userInfo!.nbDonation! > 0 ? (
                  <>
                    {t("you-have-made")}
                    &nbsp;{userInfo!.nbDonation!}&nbsp;
                    {t("number-donation")}
                  </>
                ) : (
                  <>{t("user-no-donation-slogan")}</>
                )}
              </div>
            </div>
            <div
              className={`${styles.searchIconContainer} `}
              onClick={_onClickSearchButton}
            >
              <Image className={styles.searchIcon} src={searchImg}/>
            </div>
          </div>
          {_renderSlider()}
          {_renderPopup()}
          <div className="mb24">
            <PopularAccounts data={popularUser}/>
          </div>
          <div>
            {!loadingDonateTarget &&
            (!homeDonateTarget?.list ||
              homeDonateTarget?.list?.length === 0) ? (
              _renderNoTarget()
            ) : (
              <PopularTargets data={homeDonateTarget?.list}/>
            )}
          </div>
          {!!loadingMore && <Loading containerClassName={"columnCenter"}/>}
          <div className="heartBarSpacer"/>
        </div>
      </PullToRefresh>
      <HeaderBottomBar/>
    </div>
  );
};

export default Home;
