import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import UserProfile from "src/models/UserProfile";
import PopularAccount from "./PopularAccount";
import styles from "./PopularAccounts.module.scss";

const VIEW_ALL_LENGTH = 20;
interface PopularAccountsProps {
  data?: UserProfile[];
}
const PopularAccounts = ({ data }: PopularAccountsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const showAllHandler = () => {
    history.push("/search-account");
  };

  const _handeViewTargetByUser = (user: UserProfile) => {
    history.push(`/search-target?userId=${user.id}&fullname=${user.fullname}`);
  };

  const _renderPopularAccountItem = () => {
    const listPopularUser: React.ReactNode[] = [];
    for (let i = 0; i < data!.length; i++) {
      const item = data![i];
      listPopularUser.push(
        <div
          className={i === 0 ? "ml6" : i >= data!.length - 1 ? "mr6" : ""}
          key={item.id}
        >
          <PopularAccount data={data![i]} onClick={_handeViewTargetByUser} />
        </div>
      );
    }
    return listPopularUser;
  };

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div className="rowSpaceBetween mb14 marginHori16">
        <div className={`body16 w700`}>
          <div>{t("popular-account")}</div>
        </div>
        {data?.length && data?.length >= VIEW_ALL_LENGTH && (
          <div
            className={`${styles.showAllText} body14`}
            onClick={showAllHandler}
          >
            {t("show-all")}
          </div>
        )}
      </div>

      <div className={`rowScroll`}>{_renderPopularAccountItem()}</div>

      <div></div>
    </div>
  );
};

export default PopularAccounts;
