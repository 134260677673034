import styles from "./Donate.module.scss";
import Image from "src/components/Image";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import BlurToolbar from "src/components/BlurToolbar";
import donateImg from "src/assets/images/donate.gif";
import CircleUserAvatar from "src/components/CircleUserAvatar";
import Chip from "src/components/Chip";
import Utils from "src/utils/Utils";
import SwitchListTile from "src/components/SwitchListTile";
import Input from "src/components/Input";
import { useCallback, useEffect, useState } from "react";
import { getWish, donate, getDonateTargetDetail } from "src/api";
import useLocalStorage from "src/hooks/UseLocalStorage";
import Wish from "src/models/Wish";
import qs from "qs";
import { useLocation, useHistory } from "react-router";
import UiService from "src/services/UiService";
import DonateResponse from "src/models/DonateResponse";
import DonateTarget from "src/models/DonateTarget";
import WindowSize from "src/models/WindowSize";

const Donate = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const userId = queryParams?.userId;
  const donateTargetId = queryParams?.donateTargetId;
  const { t } = useTranslation();
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [donateMessage, setDonateMessage] = useState<string>("");
  const [moneyAmount, setMoneyAmount] = useState<string>("");
  const [errMoneyAmount, setErrMoneyAmount] = useState<string>("");
  const [wishes, setWishes] = useLocalStorage<Wish[]>("wishes");
  const [showingKeyboard, setShowingKeyboard] = useState<boolean>(false);
  const [originWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [blurMoneyInputTimeout, setBlurMoneyInputTimeout] =
    useState<NodeJS.Timeout | null>();
  const [blurMessageInputTimeout, setBlurMessageInputTimeout] =
    useState<NodeJS.Timeout | null>();
  const [donateTargetData, setDonateTargetData] = useLocalStorage<DonateTarget>(
    "donate-target-" + donateTargetId
  );
  const [showWishList, setShowWishList] = useState<boolean>(false);
  const _getWish = useCallback(async () => {
    try {
      const wishRes = await getWish();
      console.log("wishRes", wishRes);
      setWishes(wishRes.data);
    } catch (err) {
      console.log("Wish err", err);
    }
  }, []);

  const _loadDonateTargetDetail = useCallback(async () => {
    try {
      const donateTargetDetailRes = await getDonateTargetDetail(donateTargetId);
      console.log("donateTargetDetailRes", donateTargetDetailRes);
      const donateTarget = donateTargetDetailRes?.data as DonateTarget;
      setDonateTargetData(donateTarget);
    } catch (err: any) {
      console.log("Load donateTarget err", err?.response);

      if (err?.response?.data?.code === 400) {
        history.replace("/not-found");
      }
    }
  }, [donateTargetId, history, setDonateTargetData]);

  useEffect(() => {
    _loadDonateTargetDetail();
    _getWish();
  }, []);

  const _handleResize = useCallback(() => {
    if (
      window.innerWidth + window.innerHeight !==
      originWindowSize.width + originWindowSize.height
    ) {
      setShowingKeyboard(true);
    } else {
      setShowingKeyboard(false);
    }
  }, [originWindowSize]);

  useEffect(() => {
    window.addEventListener("resize", _handleResize);
    return () => window.removeEventListener("resize", _handleResize);
  }, [_handleResize]);

  const _handleChangeAnonymous = (value: boolean) => {
    setAnonymous(value);
  };

  const _donate = async (amountNumber: number) => {
    try {
      UiService.showLoading();
      const donateRes = await donate({
        amount: amountNumber,
        message: donateMessage,
        donateTargetId,
        userId: donateTargetData?.owner?.id || userId,
        incognito: anonymous,
      });
      console.log("Donate Res", donateRes);
      const donateResponse = donateRes.data as DonateResponse;
      Utils.sendPayment({
        bankCode: donateResponse.payment?.bankAccount.bankCode,
        accountNo: donateResponse.payment?.bankAccount.accountNo,
        amount: donateResponse.amount,
        description: donateResponse.payment?.paymentDescription,
      });
      UiService.hideLoading();
    } catch (err: any) {
      UiService.hideLoading();
      console.log("Donate err", err?.response);
      if (err?.response?.data?.error_code === "invalid-donate-target-id") {
        UiService.openPopup({
          content: t("invalid-donate-target"),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
            history.push("/");
          },
        });
      } else if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: t("general_error"),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleDonate = async () => {
    console.log("_handleDonate", donateTargetData?.owner?.id, userId);
    if (!donateTargetId) {
      UiService.openPopup({
        content: t("err-invalid-param"),
        onlyOkButton: true,
        okTitle: t("close"),
        onOk: () => {
          UiService.closePopup();
        },
      });
      return;
    }
    if (!moneyAmount || !moneyAmount.trim()) {
      setErrMoneyAmount(t("err-donate-amount-required"));
      return;
    }
    const amountNumber = +moneyAmount.replace(/\D/g, "");
    if (amountNumber < 1) {
      setErrMoneyAmount(t("err-donate-amount-larger-than-1"));
      return;
    } else if (amountNumber > 500000000) {
      setErrMoneyAmount(t("err-donate-amount-smaller-than-500m"));
      return;
    }

    try {
      UiService.showLoading();
      const donateTargetDetailRes = await getDonateTargetDetail(donateTargetId);
      console.log("donateTargetDetailRes", donateTargetDetailRes);
      const donateTarget = donateTargetDetailRes?.data as DonateTarget;
      if (Utils.isFinishedTarget(donateTarget)) {
        UiService.hideLoading();
        UiService.openPopup({
          content: t("donate-target-pause-or-finish"),
          okTitle: t("continue"),
          onOk: () => {
            UiService.closePopup();
            _donate(amountNumber);
          },
          cancelTitle: t("exit"),
          onCancel: () => {
            UiService.closePopup();
            history.push("/");
          },
        });
      } else {
        _donate(amountNumber);
      }
    } catch (err: any) {
      console.log("Load donateTarget err", err?.response);
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: t("invalid-donate-target"),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
            history.goBack();
          },
        });
      }
    }
  };

  const _handleCliclMoneySelectorItem = (moneyStr: string) => {
    setMoneyAmount(moneyStr);
    setErrMoneyAmount("");
  };

  const _renderQuickMoneySelector = () => {
    return (
      <div className={styles.moneyQuickSelectorContainer}>
        <div className="mr8">
          <CircleUserAvatar
            size={58}
            url={donateTargetData?.owner?.profilePhoto?.small?.url}
          />
        </div>
        <div className="flex1">
          <div className="rowStart mb8">
            <Chip
              text={Utils.formatMoney(50000)}
              selected={false}
              className={`flex1 mr8 ${styles.moneyChip}`}
              onClick={_handleCliclMoneySelectorItem}
              inactiveContainerClassName={styles.moneyChip}
            />
            <Chip
              text={Utils.formatMoney(100000)}
              selected={false}
              className={`flex1 ${styles.moneyChip}`}
              onClick={_handleCliclMoneySelectorItem}
            />
          </div>
          <div className="rowStart">
            <Chip
              text={Utils.formatMoney(200000)}
              selected={false}
              className={`flex1 mr8 ${styles.moneyChip}`}
              onClick={_handleCliclMoneySelectorItem}
            />
            <Chip
              text={Utils.formatMoney(500000)}
              selected={false}
              className={`flex1 ${styles.moneyChip}`}
              onClick={_handleCliclMoneySelectorItem}
            />
          </div>
        </div>
      </div>
    );
  };

  const _handleChangeDonateMessage = (e) => {
    setDonateMessage(e.target.value);
  };

  const _handleClearDonateMessage = () => {
    setDonateMessage("");
  };

  const _handleChangeMoney = (e) => {
    // const newValue = e.target.value;
    // setMoneyAmount(Utils.formatMoney(newValue));
    // setErrMoneyAmount("");
    const newValue = e.target.value;
    const caretStart = e.target.selectionStart;
    setMoneyAmount(newValue);
    setErrMoneyAmount("");
    const newValueFormatted = Utils.formatMoney(newValue);
    const newValueWithoutFormat = newValue.replace(/\D/g, "");
    const previousMoney = Utils.formatMoney(moneyAmount);
    window.requestAnimationFrame(() => {
      let newCaretStart = caretStart;
      if (
        newCaretStart === previousMoney.length + 1 &&
        newValueFormatted.length > previousMoney.length &&
        newValueWithoutFormat.length % 3 === 1
      ) {
        newCaretStart++;
      } else if (
        newCaretStart < previousMoney.length + 1 &&
        newValueFormatted.length > previousMoney.length
      ) {
        const previousNumOfDotBeforeCaret =
          previousMoney.substring(0, newCaretStart).split(".").length - 1;
        const currentNumOfDotBeforeCaret =
          newValueFormatted.substring(0, newCaretStart).split(".").length - 1;
        if (currentNumOfDotBeforeCaret > previousNumOfDotBeforeCaret) {
          newCaretStart++;
        } else if (currentNumOfDotBeforeCaret < previousNumOfDotBeforeCaret) {
          newCaretStart--;
        }
      }
      e.target.setSelectionRange(newCaretStart, newCaretStart);
    });
  };

  const _handleClickDonateMessage = (message: string) => {
    setDonateMessage(message);
  };

  const _renderDonateMessageItem = (item: Wish, index: number) => {
    return (
      <div key={item.id} className="mb4">
        <Chip
          selected={false}
          text={item.content}
          className={styles.donateMessageItem}
          onClick={_handleClickDonateMessage}
        />
      </div>
    );
  };

  const _clearBlurTimeout = () => {
    if (blurMoneyInputTimeout) {
      console.log("Clear blurMoneyInputTimeout");
      clearTimeout(blurMoneyInputTimeout);
      setBlurMoneyInputTimeout(null);
    }
    if (blurMessageInputTimeout) {
      console.log("Clear blurMessageInputTimeout");
      clearTimeout(blurMessageInputTimeout);
      setBlurMessageInputTimeout(null);
    }
  };

  const _handleFocusDonateMessage = (e) => {
    _clearBlurTimeout();
    setShowingKeyboard(true);
    setShowWishList(true);
  };

  const _handleBlurDonateMessage = (e) => {
    const blurMessageTimeout = setTimeout(() => {
      setShowingKeyboard(false);
      setBlurMessageInputTimeout(null);
    }, 300);
    setBlurMessageInputTimeout(blurMessageTimeout);
    setTimeout(() => {
      setShowWishList(false);
    }, 100);
  };

  const _handleFocusMoneyInput = () => {
    _clearBlurTimeout();
    setShowingKeyboard(true);
  };

  const _handleBlurMoneyInput = () => {
    const blurMoneyTimeout = setTimeout(() => {
      setShowingKeyboard(false);
      setBlurMoneyInputTimeout(null);
    }, 300);
    setBlurMoneyInputTimeout(blurMoneyTimeout);
  };

  const _renderListDonateMessage = () => {
    if (!showWishList) {
      return <div />;
    }
    return <div className="mb24">{wishes?.map(_renderDonateMessageItem)}</div>;
  };

  return (
    <div className="screenContainer">
      <BlurToolbar />
      <div className="toolbarContainerTop" />
      <div className={styles.topSpacer} />
      <div className="rowCenter mb30">
        <Image src={donateImg} className={styles.donateImg} />
      </div>
      <div className={styles.content}>
        <div className="mb24">{_renderQuickMoneySelector()}</div>
        <div className="mb24">
          <Input
            label={t("or-input-donate-money")}
            value={Utils.formatMoney(moneyAmount)}
            onChange={_handleChangeMoney}
            className={styles.moneyInput}
            errorText={errMoneyAmount}
            maxLength={15}
            inputMode={"numeric"}
            placeholder={"0"}
            rightComponent={
              <div className="body16 w600 textPlaceholder">{t("vnd")}</div>
            }
            onFocus={_handleFocusMoneyInput}
            onBlur={_handleBlurMoneyInput}
          />
        </div>

        <div className="mb24">
          <SwitchListTile
            checked={anonymous}
            onChange={_handleChangeAnonymous}
            text={t("i-want-donate-anonymous")}
          />
        </div>
        <div className="mb10">
          <Input
            label={t("donate-message")}
            placeholder={t("donate-message-placeholder")}
            onChange={_handleChangeDonateMessage}
            value={donateMessage}
            onClear={_handleClearDonateMessage}
            onFocus={_handleFocusDonateMessage}
            onBlur={_handleBlurDonateMessage}
            maxLength={150}
            className={styles.donateMessageInput}
          />
        </div>
        {_renderListDonateMessage()}
      </div>
      {!showingKeyboard && <div className="bottomButtonSpacer" />}

      {!showingKeyboard && (
        <div className="bottomButtonBlockBlur">
          <Button
            variant="primary"
            className="fullWidth"
            onClick={_handleDonate}
          >
            {t("donate-now")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Donate;
