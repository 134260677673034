import firebaseApp from './';
import { getAnalytics, logEvent } from 'firebase/analytics';

class Analytics {
  constructor(firebaseApp) {
    this.analytics = getAnalytics(firebaseApp);
  }

  logEvent = eventName => {
    logEvent(this.analytics, eventName);
  };

  logError = () => {};

  logAction = () => {};
}

export default new Analytics(firebaseApp);
