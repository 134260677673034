import { HorizontalStepperItem } from "./components/HorizontalStepper";
import documentAddImg from "./assets/images/document-add.svg";
import questionMarkCircleImg from "./assets/images/question-mark-circle.svg";
import informationCircleImg from "./assets/images/information-circle.svg";
// Preload image
import noInternetImg from "src/assets/images/no-internet.png";
import chevronRightImg from "src/assets/images/chevron-right.svg";
import chevronLeft from "src/assets/images/chevron-left.svg";
import searchImg from "src/assets/images/search.svg";
import closeGrayImg from "src/assets/images/close-gray.svg";
import closeWhiteImg from "src/assets/images/close-white.svg";
import errorLoginImg from "src/assets/images/error-login.png";

export const BUY_INSURANCE_STEP_DATA: Array<HorizontalStepperItem> = [
  {
    name: "fill-personal-info",
    icon: documentAddImg,
  },
  {
    name: "health-question",
    icon: questionMarkCircleImg,
  },
  {
    name: "confirm-info",
    icon: informationCircleImg,
  },
];

export const OTP_LENGTH: number = 6;
export const OTP_TIME = 60; // seconds;
export const REPLACE_IDENTIFIER = "*****";

export const VN_COUNTRY_CODE: string = "VN";

export enum ProductCode {
  CANCER = "CANCER",
  SPA = "SPA",
}

export enum PromotionStatus {
  HAPPENING = "HAPPENING",
  FINISHED = "FINISHED",
}

export enum HomeNewsPromotionType {
  PROMOTION = "PROMOTION",
  NEWS = "EVENT_NEW",
}

export enum ProductType {
  PRODUCT_SUPPORT = "PRODUCT_SUPPORT",
  PRODUCT_MAIN = "PRODUCT_MAIN",
}
export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}
export const GenderDisplayMap = {
  [Gender.MALE]: "male",
  [Gender.FEMALE]: "female",
};
// export enum CardType {
//   PASSPORT = "FS0002",
//   CMND = "ZMBL02",
//   CCCD = "ZMBL03",
//   CMTQD = "ZMBL18",
// }

export enum CardType {
  PASSPORT = "PASSPORT",
  CMND = "CMND",
  CCCD = "CCCD",
  CMTQD = "CMTQD",
}

export const CardTypeSortDisplayMap = {
  [CardType.PASSPORT]: "passport",
  [CardType.CMND]: "cmnd-sort",
  [CardType.CCCD]: "cccd-sort",
  [CardType.CMTQD]: "military-card-sort",
};

export const CARD_TYPE_VALUES = [
  { name: "passport", value: CardType.PASSPORT },
  { name: "cmnd", value: CardType.CMND },
  { name: "cccd", value: CardType.CCCD },
  { name: "military-card", value: CardType.CMTQD },
];
export const PAGE_SIZE = 10;

export const FREE_CANCER_SUM_ASSURED = 100000000;
export const CANCER_URL =
  "https://www.mbageas.life/san-pham/bao-hiem-ung-dung-song-khoe";
export const MBAGEAS_LIFE_URL = "https://www.mbageas.life/";
export const INSURANCE_PAYMENT_GUIDE_URL =
  "https://mbageas.life/page/dong-phi-bao-hiem";
export const CARD_NUMBER_MIN_LENGTH = 16;
export const CARD_NUMBER_START = "9704";
export const CARD_NUMBER_MAX_LENGTH = 19;
export const CARD_NUMBER_DISPLAY_MAX_LENGTH = 23;
export const PRELOAD_IMAGES = [
  noInternetImg,
  chevronRightImg,
  chevronLeft,
  searchImg,
  closeGrayImg,
  closeWhiteImg,
  errorLoginImg,
];

export enum ROOT_LOCATION_ACTION {
  CHARGE = "CHARGE",
}

export enum DONATE_TARGET_STATUS {
  ON_GOING = "ON_GOING",
  PAUSE = "PAUSE",
  COMPLETED = "COMPLETED",
  COMPLETE_TARGET = "COMPLETE_TARGET",
  DRAFT = "DRAFT",
  UNAVAILABLE = "UNAVAILABLE",
  FINISHED = "FINISHED",
}

export enum SEARCH_TARGET_STATUS {
  ON_GOING = "ON_GOING",
  COMPLETE_TARGET = "COMPLETE_TARGET",
  FINISHED = "FINISHED",
}

export enum SOCIAL_INFO_TYPE {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  THIENNGUYEN = "THIENNGUYEN",
}

export const THIENNGUYEN_APP_DEEPLINK = "https://thiennguyen.page.link/dl";
export const THIENNGUYEN_DEV_APP_DEEPLINK = "https://thiennguyenappdev.page.link";
export const RECENT_DONATOR_FIRST_PAGE_SIZE = 5;
export const RECENT_DONATOR_NEXT_PAGE_SIZE = 15;
export const RECENT_DONATOR_MAX_RECORD = 20;
export const DEFAULT_PAGE_SIZE = 20;

export enum BANNER_LINK_NAVIGATION_TYPE {
  INAPP_BROWSER_LINK = "INAPP_BROWSER_LINK",
  EXTERNAL_BROWSER_LINK = "EXTERNAL_BROWSER_LINK",
  MINIAPP_TRANSFER = "MINIAPP_TRANSFER",
}
