import Image from "src/components/Image";
import styles from "./DonateTargetItem.module.scss";
import CircleUserAvatar from "../CircleUserAvatar";
import ProgressBar from "../ProgressBar";
import Utils from "src/utils/Utils";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import TimeTag from "../TimeTag";
import { useHistory } from "react-router-dom";
import DonateTarget from "src/models/DonateTarget";
import DonatorList from "../DonatorList";
import checkedPrimaryImg from "src/assets/images/checked-primary.png";
import UiService from "src/services/UiService";
import DonateResponse from "src/models/DonateResponse";
import { donate, getDonateTargetDetail } from "src/api";

interface DonateTargetItemProps {
  data: DonateTarget;
}
const DonateTargetItem = ({ data }: DonateTargetItemProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isFinishedTarget = Utils.isFinishedTarget(data);
  const isOnGoingTarget = Utils.isOnGoingTarget(data);

  const _buildDonateButton = () => {
    if (isFinishedTarget) {
      return <div />;
    }
    return (
      <Button
        variant="primary"
        className={`${styles.donateButton}`}
        onClick={_handleClickDonate}
      >
        <span className="body13 w600">{t("donate")}</span>
      </Button>
    );
  };

  const _donate = async () => {
    try {
      UiService.showLoading();
      const donateRes = await donate({
        amount: null,
        message: null,
        donateTargetId: data.id!,
        userId: data?.owner?.id || "",
        incognito: false,
      });
      console.log("Donate Res", donateRes);
      const donateResponse = donateRes.data as DonateResponse;
      Utils.sendPayment({
        bankCode: donateResponse.payment?.bankAccount.bankCode,
        accountNo: donateResponse.payment?.bankAccount.accountNo,
        description: donateResponse.payment?.paymentDescription,
      });
      UiService.hideLoading();
    } catch (err: any) {
      UiService.hideLoading();
      console.log("Donate err", err?.response);
      if (err?.response?.data?.error_code === "invalid-donate-target-id") {
        UiService.openPopup({
          content: t("invalid-donate-target"),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
            history.push("/");
          },
        });
      } else if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: t("general_error"),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleDonate = async () => {
    console.log("_handleDonate");
    if (!data.id) return;
    try {
      UiService.showLoading();
      const donateTargetDetailRes = await getDonateTargetDetail(data.id!);
      console.log("donateTargetDetailRes", donateTargetDetailRes);
      const donateTarget = donateTargetDetailRes?.data as DonateTarget;
      if (Utils.isFinishedTarget(donateTarget)) {
        UiService.hideLoading();
        UiService.openPopup({
          content: t("donate-target-pause-or-finish"),
          okTitle: t("continue"),
          onOk: () => {
            UiService.closePopup();
            _donate();
          },
          cancelTitle: t("exit"),
          onCancel: () => {
            UiService.closePopup();
            history.push("/");
          },
        });
      } else {
        _donate();
      }
    } catch (err: any) {
      console.log("Load donateTarget err", err?.response);
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: t("invalid-donate-target"),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
            history.goBack();
          },
        });
      }
    }
  };

  const _handleClickDonate = (e) => {
    console.log("_handleClickDonate");
    e.stopPropagation();
    _handleDonate();
    // history.push(`/donate?donateTargetId=${data.id}&userId=${data.owner?.id}`);
  };

  const _handleClickItem = () => {
    console.log("_handleClickItem");
    history.push(`/donate-target?id=${data.id}`);
  };

  const _renderMoneyBlock = () => {
    return (
      <div className="pt4 mb4">
        <span
          className={`body13 w600 ${
            isFinishedTarget ? "textSuccess" : "textPrimary"
          }`}
        >
          {Utils.formatMoney(
            isOnGoingTarget ? data?.remainingAmount : data?.actualAmount
          )}{" "}
          {t("vnd")}
        </span>
        <span
          className={`body12 textPrimary ${
            isFinishedTarget ? "textSuccess" : "textPrimary"
          }`}
        >
          &nbsp;
          {t(isOnGoingTarget ? "to-finish-target" : "finished-lc")}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.targetContainer} onClick={_handleClickItem}>
      <div className="position-relative">
        <div
          className={styles.image}
          style={{ backgroundImage: `url("${data.photo?.small?.url}")` }}
        >
          {(!!data.endTime || !!data.paused) && (
            <TimeTag
              margin={12}
              text={Utils.getDonateTargetTimeRemainingText(data, t)}
            />
          )}
        </div>
        <div className={styles.imageContent}>
          {/* <div className={styles.creatorCard}></div> */}
          <div className={`${styles.creatorContent} rowStart`}>
            <div className="mr6">
              <CircleUserAvatar
                size={34}
                url={data.owner?.profilePhoto?.small?.url}
              />
            </div>
            <div>
              <div className={`body14 w600 textOnPrimary breakWord`}>
                <span className="verticalMiddle">{data.owner?.fullname}</span>
                <Image
                  src={checkedPrimaryImg}
                  className={"verifiedCheckSmall"}
                />
              </div>
              <div className={`body12 textGray6 breakWord ellipsisOne`}>
                {Utils.getDisplayUserName(data.owner?.username)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.contentContainer}`}>
        <div className={`paddingHori12`}>
          <div className={`body15 w700 pt16 ${styles.name}`}>{data.name}</div>
          {_renderMoneyBlock()}
          <ProgressBar
            progress={Utils.getDonateProgress(data)}
            finished={isFinishedTarget}
          />
          <div className="pb16"></div>
          <div className="rowStart pb8">
            <DonatorList
              donators={data.recentDonators}
              nbDonator={data.nbDonator}
              numberDonatorsShow={4}
              stackDonators={true}
              horizontalNumberDonators={true}
              buildHorizontalButton={_buildDonateButton()}
              finished={isFinishedTarget}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateTargetItem;
