import styles from "./SearchToolbar.module.scss";
import Image from "src/components/Image";
import backBlackImg from "src/assets/images/back-black.svg";
import searchInputImg from "src/assets/images/search_gray.svg";
import searchImg from "src/assets/images/search.svg";
import { useImperativeHandle, useRef, useState } from "react";
import Input from "../Input";
import Toolbar from "../Toolbar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React from "react";

interface SearchToolbarProps {
  searchState: boolean;
  onChangeSearchState: (boolean) => void;
  title: string;
  placeHolder?: string;
  keyword: string;
  inputMaxLength?: number;
  onChangeKeyword?: (any) => void;
  onFocusInput?: () => void;
  onBlurInput?: () => void;
  onClearInput?: () => void;
}
const SearchToolbar = React.forwardRef(
  (
    {
      searchState,
      onChangeSearchState,
      title,
      placeHolder,
      keyword,
      inputMaxLength,
      onChangeKeyword,
      onFocusInput,
      onBlurInput,
      onClearInput,
    }: SearchToolbarProps,
    ref
  ) => {
    const { t } = useTranslation();
    const [focus, setFocus] = useState<boolean>(searchState);
    const history = useHistory();
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [canBack, setCanBack] = useState(true);
    // const handleBack = useCallback(() => {
    //   if (history.length >= 2) {
    //     history.goBack();
    //   }
    // }, [history]);

    useImperativeHandle(ref, () => ({
      blur: () => {
        inputRef?.current.blur();
      },
    }));

    const backToHome = () => {
      history.push("/");
    };

    const _onFocus = () => {
      setFocus(true);
    };

    const _offFocus = () => {
      setFocus(false);
    };

    const handleClickCancel = () => {
      _offFocus();
      if (onClearInput) onClearInput();
    };

    const handleChangeSearchState = () => {
      setCanBack(false);
      if (searchState) {
        onChangeSearchState(false);
        _offFocus();
        if (onClearInput) onClearInput();
      } else {
        onChangeSearchState(true);
        _onFocus();
      }
    };

    const handleBlurInput = () => {
      if (onBlurInput) onBlurInput();
      _offFocus();
    };

    const _renderInput = () => {
      return (
        <div
          className={`paddingHori16 searchContainer toolbarContainerTop mb20 rowStart`}
        >
          <Image
            src={backBlackImg}
            className={styles.arrowBackImg}
            onClick={canBack ? backToHome : handleChangeSearchState}
          />
          <div className="flex1 mr10">
            <Input
              ref={inputRef}
              leftComponent={
                <Image src={searchInputImg} className={styles.searchInputImg} />
              }
              value={keyword}
              onChange={onChangeKeyword}
              containerClassName={styles.searchInputContainer}
              onClear={onClearInput}
              placeholder={t(`${placeHolder}`)}
              autoFocus={focus}
              onFocus={onFocusInput}
              onBlur={handleBlurInput}
              maxLength={inputMaxLength}
            />
          </div>
          <span
            className="body14 w500 textBlack3"
            onClick={handleChangeSearchState}
          >
            {t("cancel-short")}
          </span>
        </div>
      );
    };

    const _renderBackButton = () => {
      return (
        <div className="mb4">
          <Toolbar
            title={t(`${title}`)}
            type="normal"
            suffixButtonUrl={searchImg}
            onClickSuffixButton={handleChangeSearchState}
            toolbarClassName={styles.toolbar}
            onBack={backToHome}
          />
        </div>
      );
    };

    return searchState ? _renderInput() : _renderBackButton();
  }
);

export default SearchToolbar;
