import styles from "./DonateTargetCreator.module.scss";
import CircleUserAvatar from "../CircleUserAvatar";
import checkedPrimaryImg from "src/assets/images/checked-primary.png";
import Image from "src/components/Image";
import forwardPrimaryImg from "src/assets/images/forward-primary.svg";
import UserProfile from "src/models/UserProfile";
import Utils from "src/utils/Utils";
interface DonateTargetCreatorProps {
  onView?: () => void;
  data?: UserProfile;
}
const DonateTargetCreator = ({ data, onView }: DonateTargetCreatorProps) => {
  return (
    <div className={styles.container} onClick={onView}>
      <div className="mr10">
        <CircleUserAvatar size={58} url={data?.profilePhoto.small?.url} />
      </div>
      <div className="flex1">
        <div className="subTitle16 textBlack2 mb2 breakWord">
          <span className="verticalMiddle">{data?.fullname}</span>
          <Image className={"verifiedCheckSmall"} src={checkedPrimaryImg} />
        </div>
        <div className="body14 textGray3 breakWord">
          {Utils.getDisplayUserName(data?.username)}
        </div>
      </div>
      <Image
        src={forwardPrimaryImg}
        className={styles.forwardImg}
        onClick={onView}
      />
    </div>
  );
};

export default DonateTargetCreator;
