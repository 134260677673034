import { useTranslation } from "react-i18next";
import DonateTarget from "src/models/DonateTarget";
import Utils from "src/utils/Utils";
import ProgressBar from "../ProgressBar";
import TimeTag from "../TimeTag";
import styles from "./DonateTargetItem2.module.scss";
interface DonateTargetItem2Props {
  data: DonateTarget;
  onClick?: (DonateTarget) => void;
}
const DonateTargetItem2 = ({ data, onClick }: DonateTargetItem2Props) => {
  const { t } = useTranslation();
  const progressDisplay = Utils.getDonateProgressPercentageText(data);

  const _handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${data.photo?.large?.url}")`,
      }}
      onClick={_handleClick}
    >
      {(!!data.endTime || !!data.paused) && (
        <TimeTag
          margin={8}
          text={Utils.getDonateTargetTimeRemainingText(data, t)}
        />
      )}

      <div className="flex1" />
      <div className={styles.info}>
        <div
          className={`${styles.targetName} subTitle15 textOnPrimary mb8 breakWord`}
        >
          {data.name}
        </div>
        <div className="mb8">
          <ProgressBar progress={Utils.getDonateProgress(data)} />
        </div>
        <div className="rowStart">
          <div className="body13 textOnPrimary flex1">
            {t("achieve")}{" "}
            <span className="w600">
              {Utils.formatMoney(data.actualAmount || 0)}
            </span>{" "}
            / {Utils.formatMoney(data.targetAmount || 0)} {t("vnd")}
          </div>
          <div className="subTitle12 textOnPrimary">{progressDisplay}</div>
        </div>
      </div>
    </div>
  );
};

export default DonateTargetItem2;
