import styles from "./BannerDetail.module.scss";
import Image from "src/components/Image";
import { useHistory, useLocation } from "react-router-dom";
import Banner from "src/models/Banner";
import BlurToolbar from "src/components/BlurToolbar";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Utils from "src/utils/Utils";
import { useCallback, useEffect, useState } from "react";
import { getBannerDetail, getDonateTargetDetail } from "src/api";
import qs from "qs";
import { BANNER_LINK_NAVIGATION_TYPE } from "src/Constants";
import Loading from "src/components/Loading";
import PullToRefresh from "react-simple-pull-to-refresh";
import UiService from "src/services/UiService";
import DonateTarget from "src/models/DonateTarget";

const BannerDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const id = queryParams?.id;
  const data = location.state as Banner;
  const [bannerData, setBannerData] = useState<Banner>(data);
  const { t } = useTranslation();

  // const _checkDonateTargetStatus = useCallback(async () => {
  //   try {
  //     UiService.showLoading();
  //     const donateTargetDetailRes = await getDonateTargetDetail(
  //       bannerData.donateTarget?.id || ""
  //     );
  //     console.log("donateTargetDetailRes", donateTargetDetailRes);
  //     const donateTarget = donateTargetDetailRes?.data as DonateTarget;
  //     UiService.hideLoading();
  //     if (Utils.isFinishedTarget(donateTarget)) {
  //       UiService.openPopup({
  //         content: t("invalid-donate-target"),
  //         onlyOkButton: true,
  //         okTitle: t("close"),
  //         onOk: () => {
  //           UiService.closePopup();
  //         },
  //       });
  //     } else {
  //       history.push(
  //         `/donate?donateTargetId=${bannerData.donateTarget?.id}&userId=${bannerData.donateTarget?.owner?.id}`
  //       );
  //     }
  //   } catch (err: any) {
  //     console.log("Load donateTarget err", err?.response);
  //     UiService.hideLoading();
  //     if (err?.response?.data?.code === 400) {
  //       UiService.openPopup({
  //         content: t("invalid-donate-target"),
  //         onlyOkButton: true,
  //         okTitle: t("close"),
  //         onOk: () => {
  //           UiService.closePopup();
  //         },
  //       });
  //     }
  //   }
  // }, [history, bannerData, t]);

  const _handleAction = () => {
    if (
      bannerData.navigationType ===
      BANNER_LINK_NAVIGATION_TYPE.INAPP_BROWSER_LINK
    ) {
      Utils.openNewWebView(
        bannerData.titleNavigationName || "",
        data.link || ""
      );
    } else if (
      bannerData.navigationType ===
      BANNER_LINK_NAVIGATION_TYPE.EXTERNAL_BROWSER_LINK
    ) {
      Utils.openBrowser(bannerData.link || "");
    } else if (
      bannerData.navigationType === BANNER_LINK_NAVIGATION_TYPE.MINIAPP_TRANSFER
    ) {
      // _checkDonateTargetStatus();
      history.push(
        `/donate?donateTargetId=${bannerData.donateTarget?.id}&userId=${bannerData.donateTarget?.owner?.id}`
      );
    }
  };

  const _loadBannerDetail = useCallback(async () => {
    try {
      const bannerDetailRes = await getBannerDetail(id);
      console.log("bannerDetailRes", bannerDetailRes);
      setBannerData(bannerDetailRes?.data);
    } catch (err: any) {
      console.log("banner detail err", err?.response);
      if (err?.response?.data?.code === 400) {
        history.replace("/not-found");
      }
    }
  }, [id, history]);

  useEffect(() => {
    _loadBannerDetail();
  }, []);

  const _handleRefresh = async () => {
    await _loadBannerDetail();
  };

  return (
    <div className="screenContainer">
      <BlurToolbar />
      <PullToRefresh
        onRefresh={_handleRefresh}
        refreshingContent={<Loading containerClassName="columnCenter" />}
        pullingContent=""
      >
        <>
          <Image src={bannerData?.photo} className={styles.bannerImg} />
          <div className={styles.content}>
            <div className="title16 textBlack2 mb21">{bannerData?.name}</div>
            <div className="body14 textGray3 prewrap">
              {bannerData?.content}
            </div>
            <div className="bottomButtonSpacer" />
          </div>
        </>
      </PullToRefresh>
      {!!bannerData?.displayNameButton && !!bannerData?.name && (
        <div className="bottomButtonBlockBlur">
          <Button
            variant="primary"
            className="fullWidth"
            onClick={_handleAction}
          >
            {bannerData.displayNameButton}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BannerDetail;
