import Image from "src/components/Image";
import styles from "./DonatorList.module.scss";
import { useTranslation } from "react-i18next";
import UserDonator from "src/models/UserDonator";
import Utils from "src/utils/Utils";
import useWindowSize from "src/hooks/UseWindowSize";
import { useState } from "react";
interface DonatorListProps {
  donators?: UserDonator[];
  numberDonatorsShow?: number;
  stackDonators?: boolean;
  horizontalNumberDonators?: boolean;
  buildHorizontalButton?: React.ReactNode;
  nbDonator?: number;
  finished?: boolean;
}

const DonatorList = ({
  donators,
  numberDonatorsShow,
  stackDonators = false,
  horizontalNumberDonators = false,
  buildHorizontalButton,
  nbDonator,
  finished,
}: DonatorListProps) => {
  const { t } = useTranslation();
  const windowWidth = useWindowSize().width;
  const [cacheIncognitoAvatar, setCacheIncognotoAvatar] = useState<
    Map<number, string>
  >(new Map<number, string>());
  const _getNumberDisplayDonator = (): number => {
    return Math.floor((windowWidth - 28) / 36);
  };

  const length = !donators
    ? 0
    : numberDonatorsShow
    ? Math.min(numberDonatorsShow, donators.length)
    : Math.min(_getNumberDisplayDonator(), donators.length);

  const _buildDonators = () => {
    const listDonator: React.ReactNode[] = [];
    for (let index = 0; index < length; index++) {
      listDonator.push(
        _renderDonator(donators![index], index, nbDonator! > length)
      );
    }
    return listDonator;
  };

  const _getDonatorImageSrc = (donator: UserDonator, index: number) => {
    if (donator.user) {
      return donator.user.profilePhoto.small?.url;
    }
    if (!cacheIncognitoAvatar[index]) {
      cacheIncognitoAvatar[index] = Utils.getIncognotoUserAvatarSrc();
    }
    return cacheIncognitoAvatar[index];
  };

  const _renderDonator = (
    item: UserDonator,
    index: number,
    hasMore: boolean
  ) => {
    const isLast = index === length - 1;
    if (isLast && hasMore) {
      return (
        <div
          className={`${styles.donatorAvatar} ${styles.avatarContainer} position-relative`}
          style={
            stackDonators ? { transform: `translateX(${16 * index}px)` } : {}
          }
          key={index}
        >
          <div className={styles.lastAvatarOverlay}>
            <div className={styles.more}>...</div>
          </div>
          <Image
            src={_getDonatorImageSrc(item, index)}
            className={`${styles.donatorAvatar}`}
          />
        </div>
      );
    }
    return (
      <Image
        src={_getDonatorImageSrc(item, index)}
        className={`${styles.donatorAvatar} ${styles.avatarContainer} ${
          stackDonators || isLast ? "" : styles.mr4
        } ${stackDonators ? "position-absolute" : ""}`}
        key={index}
        style={
          stackDonators ? { transform: `translateX(${16 * index}px)` } : {}
        }
      />
    );
  };

  const _renderNumberDonatorMessage = () => {
    const recognizedDonatorUser = donators!.filter((item) => item.user);
    const numberRemainUser = nbDonator! - recognizedDonatorUser.length;
    if (recognizedDonatorUser.length === 0) {
      return (
        <div className="body12 textBlack2">
          {Utils.formatMoney(nbDonator)}&nbsp;{t("people-donated")}
        </div>
      );
    }
    if (recognizedDonatorUser.length === 1) {
      return (
        <div className="body12 textBlack2">
          <span className="w600 breakWord">
            {recognizedDonatorUser[0].user?.fullname}
          </span>
          {numberRemainUser > 0 ? (
            <>
              &nbsp;
              {t("and")}&nbsp;{Utils.formatMoney(nbDonator! - 1)}&nbsp;
              {t("other-donated")}
            </>
          ) : (
            <> {t("donated-lc")}</>
          )}
        </div>
      );
    }

    if (recognizedDonatorUser.length >= 2) {
      return (
        <div className="body12 textBlack2">
          <span className="w600 breakWord">
            {recognizedDonatorUser[0].user?.fullname},{" "}
            {recognizedDonatorUser[1].user?.fullname}
          </span>
          {numberRemainUser > 0 ? (
            <>
              &nbsp;
              {t("and")}&nbsp;{Utils.formatMoney(nbDonator! - 2)}&nbsp;
              {t("other-donated")}
            </>
          ) : (
            <> {t("donated-lc")}</>
          )}
        </div>
      );
    }
  };
  if (
    !horizontalNumberDonators &&
    (!donators || donators.length === 0 || !nbDonator)
  ) {
    return <div />;
  }

  return (
    <div className="fullWidth">
      <div className="rowStart mb8">
        {length > 0 && (
          <div
            className={`rowStart${stackDonators ? " position-relative" : ""}`}
            style={
              stackDonators ? { width: 16 * (length + 1) + 8, height: 32 } : {}
            }
          >
            {_buildDonators()}
          </div>
        )}

        {horizontalNumberDonators && (
          <div className="body12 textGray3 flex1">
            {nbDonator && nbDonator > 0 ? (
              <>
                <span className="w600">
                  {Utils.formatMoney(nbDonator) || ""} {t("people-lc")}
                </span>{" "}
                {t("donated-lc")}
              </>
            ) : (
              <>
                <span>
                  {finished ? t("no-donator") : t("be-first-donator")}
                </span>
              </>
            )}
          </div>
        )}
        {horizontalNumberDonators && buildHorizontalButton}
      </div>
      {!horizontalNumberDonators && _renderNumberDonatorMessage()}
    </div>
  );
};

export default DonatorList;
