import Tabs from "src/components/Tabs";
import styles from "./SearchTarget.module.scss";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback, useRef } from "react";
import DonateTargetItem from "src/components/DonateTargetItem";
import HeaderBottomBar from "src/components/HeartBottomBar";
import { useHistory, useLocation } from "react-router-dom";
import { searchDonateTarget, searchUserDonateTarget } from "src/api";
import DonateTarget from "src/models/DonateTarget";
import Paging from "src/models/Paging";
import { SEARCH_TARGET_STATUS } from "src/Constants";
import Utils from "src/utils/Utils";
import NoData from "src/components/NoData";
import debounce from "lodash/debounce";
import qs from "qs";
import Loading from "src/components/Loading";
import SearchToolbar from "src/components/SearchToolbar";
import useLocalStorage from "src/hooks/UseLocalStorage";
import PullToRefresh from "react-simple-pull-to-refresh";

const SearchTarget = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const userId = queryParams?.userId;
  const fullname = queryParams?.fullname;
  const title = fullname || t("all-target");
  const history = useHistory();

  const [searchState, setSearchState] = useLocalStorage<boolean>(
    "searchTargetState",
    location.state === "home",
    history.action !== "POP"
  );
  const [keyword, setKeyword] = useLocalStorage<string>(
    "searchTargetKeyword",
    "",
    history.action !== "POP"
  );
  const [activeTabIndex, setActiveTabIndex] = useLocalStorage<number>(
    "searchTargetActiveTab",
    0,
    history.action !== "POP"
  );
  const [listDonateTarget, setListDonateTarget] =
    useState<Paging<DonateTarget>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [focusingSearchInput, setFocusingSearchInput] =
    useState<boolean>(false);
  const searchBarRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  // const searchState = location.state === "home";
  // const _resetSearch = () => {
  //   setSearchState(location.state === "home");
  //   setKeyword("");
  //   setActiveTabIndex(0);
  // };

  // useEffect(() => {
  //   console.log("History SearchTarget", history);
  //   if (history.action !== "POP") {
  //     _resetSearch();
  //   }
  // }, [history]);

  useEffect(() => {
    _loadData(_getStatusParamByTabIndex(activeTabIndex), null, keyword);
  }, []);

  const _handleRefresh = async () => {
    if (loading || loadingMore) return;
    await _loadData(
      _getStatusParamByTabIndex(activeTabIndex),
      null,
      keyword,
      true,
      true
    );
  };

  const _getStatusParamByTabIndex = (index: number): SEARCH_TARGET_STATUS => {
    if (index === 1) {
      return SEARCH_TARGET_STATUS.COMPLETE_TARGET;
    } else if (index === 2) {
      return SEARCH_TARGET_STATUS.FINISHED;
    }
    return SEARCH_TARGET_STATUS.ON_GOING;
  };

  const _loadData = useCallback(
    async (
      status: SEARCH_TARGET_STATUS,
      nextPageId?: string | null,
      keyword?: string | null,
      firstLoad: boolean | null = true,
      refreshing: boolean | undefined = false
    ) => {
      try {
        if (!refreshing) {
          if (firstLoad) {
            setLoading(true);
          } else {
            setLoadingMore(true);
          }
        }

        let listDonateTargetRes;
        if (userId) {
          listDonateTargetRes = await searchUserDonateTarget({
            donateTargetMiniappStatus: status,
            nextPageId,
            keyword,
            userId,
          });
        } else {
          listDonateTargetRes = await searchDonateTarget({
            donateTargetMiniappStatus: status,
            nextPageId,
            keyword,
          });
        }

        console.log("listDonateTarget", listDonateTargetRes);
        setLoading(false);
        setLoadingMore(false);
        if (!nextPageId) {
          setListDonateTarget(listDonateTargetRes.data);
        } else {
          setListDonateTarget({
            nextPageId: listDonateTargetRes?.data?.nextPageId,
            list: [
              ...(listDonateTarget?.list || []),
              ...(listDonateTargetRes?.data?.list || []),
            ],
          });
        }
      } catch (err: any) {
        setLoading(false);
        setLoadingMore(false);
        console.log("search target err: ", err?.response);
        if (
          err?.response?.data?.code === 400 &&
          err?.response?.data?.error_code === "invalid-user-id"
        ) {
          history.replace("/not-found");
        }
      }
    },
    [listDonateTarget?.list, userId, history]
  );

  const _search = debounce(
    (nextPageId?: string | null, keyword?: string | null) => {
      _loadData(_getStatusParamByTabIndex(activeTabIndex), nextPageId, keyword);
    },
    300
  );

  const _handleChangeSearchState = (value: boolean) => {
    setSearchState(value);
  };

  const _handleChangeKeyword = (event) => {
    const newKeyword = event.target.value;
    setKeyword(newKeyword);
    _search(null, newKeyword);
    console.log(keyword);
  };

  const _handleClearKeyword = () => {
    setKeyword("");
    _search(null, null);
  };

  const handleChangeTab = (index: number) => {
    if (index === activeTabIndex) return;
    setActiveTabIndex(index);
    _loadData(_getStatusParamByTabIndex(index), null, keyword);
  };

  const _loadMore = () => {
    if (
      !listDonateTarget?.nextPageId ||
      !listDonateTarget.list ||
      listDonateTarget.list.length === 0 ||
      loading ||
      loadingMore
    ) {
      return;
    }

    _loadData(
      _getStatusParamByTabIndex(activeTabIndex),
      listDonateTarget.nextPageId,
      keyword,
      false
    );
  };

  const _handleScroll = (e) => {
    // if (focusingSearchInput) {
    //   searchBarRef?.current?.blur();
    // }
    if (Utils.isScrollToEnd(e)) {
      _loadMore();
    }
  };

  const _handleFocus = () => {
    setFocusingSearchInput(true);
  };

  const _handleBlur = () => {
    setFocusingSearchInput(false);
  };

  const _renderTargetsByStatus = () => {
    return (
      <PullToRefresh
        onRefresh={_handleRefresh}
        refreshingContent={<Loading containerClassName="columnCenter" />}
        pullingContent=""
      >
        <div className={styles.dataContainer} onScroll={_handleScroll}>
          {!loadingMore &&
          !loading &&
          listDonateTarget?.list &&
          listDonateTarget?.list.length === 0 ? (
            <NoData text={t("no-target")} searching={!!keyword} />
          ) : loading ? (
            <Loading />
          ) : (
            listDonateTarget?.list.map((item, index) => (
              <div className="mb12" key={item.id + "-" + index}>
                <DonateTargetItem data={item} />
              </div>
            ))
          )}
          {!!loadingMore && <Loading containerClassName={"columnCenter"} />}
          {!focusingSearchInput && <div className="heartBarSpacer"></div>}
        </div>
      </PullToRefresh>
    );
  };

  return (
    <div className={`screenContainer`}>
      <SearchToolbar
        ref={searchBarRef}
        searchState={searchState}
        onChangeSearchState={_handleChangeSearchState}
        title={title}
        placeHolder={"search-donate-target"}
        keyword={keyword}
        onClearInput={_handleClearKeyword}
        onChangeKeyword={_handleChangeKeyword}
        onFocusInput={_handleFocus}
        onBlurInput={_handleBlur}
      />
      <div className="marginHori16">
        <Tabs
          data={[t("on-going"), t("achieved-goal"), t("completed")]}
          selectedTabIndex={activeTabIndex}
          onChangeTab={handleChangeTab}
        ></Tabs>
        {_renderTargetsByStatus()}
      </div>

      {!focusingSearchInput && <HeaderBottomBar />}
    </div>
  );
};

export default SearchTarget;
