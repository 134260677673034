import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import DonateTargetDetail from "./pages/DonateTargetDetail";
import KolProfile from "./pages/KolProfile";
import Donate from "./pages/Donate";
import { useCallback, useEffect, useState } from "react";
import UserInfoService from "./services/UserInfoService";
import { login, getUserInfo, logger } from "src/api";
import qs from "qs";
import LoginFailed from "./components/LoginFailed";
import SearchTarget from "./pages/SearchTarget";
import About from "./pages/About";
import BannerDetail from "./pages/BannerDetail";
import SearchAccount from "./pages/SearchAccount";
import ImageViewer from "./pages/ImageViewer";
import NotFound from "./pages/NotFound";
import UserProfile from "./models/UserProfile";
import Loading from "./components/Loading";
import Utils from "src/utils/Utils";
import { useHistory } from "react-router-dom";

const AuthRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const [logining, setLogining] = useState<boolean>(true);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [accountLocked, setAccountLocked] = useState<boolean>(false);
  const history = useHistory();

  const _loginByToken = useCallback(async () => {
    let queryUrl = location.search;
    const queryParams = qs.parse(queryUrl, { ignoreQueryPrefix: true });
    const loginToken = queryParams?.loginToken as string;
    const bannerId = queryParams?.bannerId as string;

    console.log("loginToken", loginToken);

    if (!loginToken) {
      if (!UserInfoService.getAccessToken()) {
        setLoginFailed(true);
      }
      setLogining(false);
      return;
    }

    if (
      !UserInfoService.getAccessToken() ||
      loginToken !== UserInfoService.getLoginToken()
    ) {
      try {
        setLogining(true);
        const loginRes = await login(loginToken);
        console.log("Login Res", loginRes);
        UserInfoService.setInfo(
          loginRes?.data?.access_token,
          loginRes?.data?.refresh_token,
          loginToken
        );
        UserInfoService.setUserInfoExpire(loginRes?.data.expire_at);
        const userInfoRes = await getUserInfo();
        console.log("userInfoRes", userInfoRes);
        UserInfoService.setUserInfo(userInfoRes.data as UserProfile);
        setLogining(false);
        setLoginFailed(false);
        setAccountLocked(false);
        // logger('route.bannerId: ' + bannerId);
        // ================================================================================================
        if (bannerId != null && bannerId === "HiGreen") {
          // let higreenUrl = "https://thiennguyen.app/higreen?token=" + loginRes?.data?.access_token +
          //   "&refresh_token=" + loginRes?.data?.refresh_token +
          //   "&expire_at=" + loginRes?.data?.expire_at;
          // Utils.openBrowser(higreenUrl || "https://thiennguyen.app/higreen");
          Utils.openBrowser("https://thiennguyen-bca.app?miniapp");

          // history.push(`/donate-target?id=1832356517400027136`);
        } 
        // ================================================================================================
      } catch (err: any) {
        console.log("Login err", err, err?.response);
        if (err?.response?.data?.error_code === "account-locked") {
          setAccountLocked(true);
        } else {
          setAccountLocked(false);
        }
        setLoginFailed(true);
        setLogining(false);
      }
    } else {
      setLogining(false);
      return;
    }
  }, [location]);

  useEffect(() => {
    _loginByToken();
  }, [location, _loginByToken]);
  if (logining) return <Loading />;
  if (loginFailed) {
    return <LoginFailed locked={accountLocked} />;
  }
  return <Route {...rest} render={({ location }) => children} />;
};

const AppRoutes = () => {
  return (
    <Switch>
      <AuthRoute exact path="/">
        <Home />
      </AuthRoute>
      <AuthRoute exact path="/search-target">
        <SearchTarget />
      </AuthRoute>
      <AuthRoute exact path="/search-account">
        <SearchAccount />
      </AuthRoute>
      <AuthRoute exact path="/banner-detail">
        <BannerDetail />
      </AuthRoute>
      <AuthRoute exact path="/donate-target">
        <DonateTargetDetail />
      </AuthRoute>
      <AuthRoute exact path="/kol-profile">
        <KolProfile />
      </AuthRoute>
      <AuthRoute exact path="/donate">
        <Donate />
      </AuthRoute>
      <AuthRoute exact path="/about">
        <About />
      </AuthRoute>
      <AuthRoute exact path="/image-viewer">
        <ImageViewer />
      </AuthRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
