import backWhiteImg from "src/assets/images/back-white.svg";
import backBlackImg from "src/assets/images/back-black.svg";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import styles from "./Toolbar.module.scss";
import Image from "src/components/Image";

type ToolbarProps = {
  title: string;
  onBack?: () => void;
  showBack?: boolean;
  type?: "normal" | "gradient";
  suffixButtonUrl?: string;
  onClickSuffixButton?: () => void;
  toolbarClassName?: string;
};

const Toolbar = (props: ToolbarProps) => {
  const {
    title,
    onBack,
    showBack = true,
    type = "gradient",
    suffixButtonUrl,
    onClickSuffixButton,
    toolbarClassName,
  } = props;
  const isGradient = type === "gradient";
  const history = useHistory();

  const _handleBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }
    if (history.length >= 2) {
      history.goBack();
    }
  }, [history, onBack]);

  return (
    <div
      className={`${
        isGradient
          ? styles.toolbarContainerGradient
          : styles.toolbarContainerNormal
      } toolbarContainerTop`}
    >
      <div
        className={
          !!toolbarClassName
            ? toolbarClassName + " " + styles.toolbar
            : styles.toolbar
        }
      >
        {!!showBack ? (
          <div onClick={_handleBack}>
            <Image
              src={isGradient ? backWhiteImg : backBlackImg}
              className={styles.backImg}
              alt="back"
            />
          </div>
        ) : (
          <div className={styles.backIconPlaceholder}></div>
        )}
        <div className={styles.titleContainer}>
          <div
            className={`ellipsisOne toolbarTitle ${
              isGradient ? "textOnPrimary" : "textBlack2"
            }`}
          >
            {title}
          </div>
        </div>
        {!!suffixButtonUrl && (
          <div
            className={`${styles.suffixButtonContainer} `}
            onClick={onClickSuffixButton}
          >
            <Image className={styles.suffixButton} src={suffixButtonUrl} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Toolbar;
