import styles from "./DonateTargetDetail.module.scss";
import Image from "src/components/Image";
import targetImg from "src/assets/images/target.svg";
import remainTimeImg from "src/assets/images/remain-time.svg";
import arrowRightPrimary from "src/assets/images/arrow-right-primary.svg";
import { useTranslation } from "react-i18next";
import Utils from "src/utils/Utils";
import ProgressBar from "src/components/ProgressBar";
import DonatorList from "src/components/DonatorList";
import DonateTargetCreator from "src/components/DonateTargetCreator";
import RecentDonationItem from "src/components/RecentDonationItem";
import Button from "react-bootstrap/Button";
import DonateTargetItem2 from "src/components/DonateTargetItem2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useCallback, useEffect, useState } from "react";
import CarouselIndicator from "src/components/CarouselIndicator";
import BlurToolbar from "src/components/BlurToolbar";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import {
  getDonateTargetDetail,
  getDonateTargetsSameAccount,
  getDonateTargetListDonation,
} from "src/api";
import UiService from "src/services/UiService";
import DonateTarget from "src/models/DonateTarget";
// import ShowMoreText from "react-show-more-text";
import ViewMoreText from "src/components/ViewMoreText";
import Photo from "src/models/Photo";
import Paging from "src/models/Paging";
import {
  RECENT_DONATOR_FIRST_PAGE_SIZE,
  THIENNGUYEN_APP_DEEPLINK,
  RECENT_DONATOR_MAX_RECORD,
  RECENT_DONATOR_NEXT_PAGE_SIZE, THIENNGUYEN_DEV_APP_DEEPLINK,
} from "src/Constants";
import ImageViewerParams from "src/models/ImageViewerParams";
import Donation from "src/models/Donation";
import Loading from "src/components/Loading";
import useLocalStorage from "src/hooks/UseLocalStorage";
import useConfig from "src/hooks/UseConfig";
import PullToRefresh from "react-simple-pull-to-refresh";
import infoPrimaryImg from "src/assets/images/info-primary.png";

const DonateTargetDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const donateTargetId = queryParams?.id;
  const history = useHistory();
  const config = useConfig();
  const [activeDonateSliderIndex, setActiveDonateSliderIndex] =
    useState<number>(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [donateTargetData, setDonateTargetData] = useLocalStorage<DonateTarget>(
    "donate-target-" + donateTargetId
  );
  const [donateTargetSameAccount, setDonateTargetSameAccount] =
    useState<DonateTarget[]>();
  const [listDonation, setListDonation] = useState<Paging<Donation>>();
  const [loadingMoreDonation, setLoadingMoreDonation] =
    useState<boolean>(false);

  const _loadRecentDonation = useCallback(
    async (nextPageId?: string | null, pageSize?: number) => {
      try {
        if (nextPageId) {
          setLoadingMoreDonation(true);
        }
        const recentDonationRes = await getDonateTargetListDonation(
          donateTargetId,
          nextPageId,
          pageSize || RECENT_DONATOR_FIRST_PAGE_SIZE
        );
        console.log("recentDonationRes", recentDonationRes);
        setLoadingMoreDonation(false);
        if (!nextPageId) {
          setListDonation(recentDonationRes?.data);
        } else {
          setListDonation({
            nextPageId: recentDonationRes?.data?.nextPageId,
            count: recentDonationRes?.data?.count,
            list: [
              ...(listDonation?.list || []),
              ...(recentDonationRes?.data?.list || []),
            ],
          });
        }
      } catch (err: any) {
        setLoadingMoreDonation(false);
        console.log("Load recent donation err", err?.response);
      }
    },
    [donateTargetId, listDonation]
  );

  const _shouldShowOverlayLoading = useCallback(() => {
    return !donateTargetData;
  }, [donateTargetData]);

  const _loadDonateTargetDetail = useCallback(async () => {
    try {
      _shouldShowOverlayLoading() && UiService.showLoading();
      const donateTargetDetailRes = await getDonateTargetDetail(donateTargetId);
      console.log("donateTargetDetailRes", donateTargetDetailRes);
      const donateTarget = donateTargetDetailRes?.data as DonateTarget;
      setDonateTargetData(donateTarget);
      _shouldShowOverlayLoading() && UiService.hideLoading();
      _loadRecentDonation();
      const donateTargetSameAccountRes = await getDonateTargetsSameAccount(
        donateTarget.owner?.id || "",
        donateTargetId
      );
      console.log("donateTargetSameAccountRes", donateTargetSameAccountRes);
      setDonateTargetSameAccount(donateTargetSameAccountRes?.data?.list || []);
    } catch (err: any) {
      console.log("Load donateTarget err", err?.response);
      _shouldShowOverlayLoading() && UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        history.replace("/not-found");
      }
    }
  }, [
    donateTargetId,
    history,
    _loadRecentDonation,
    _shouldShowOverlayLoading,
    setDonateTargetData,
  ]);

  useEffect(() => {
    _loadDonateTargetDetail();
  }, [donateTargetId]);

  const _handleRefresh = async () => {
    await _loadDonateTargetDetail();
  };

  const _handleClickImage = (index: number) => {
    const imageViewerParasm: ImageViewerParams = {
      index,
      images: getDonateImages(),
    };
    history.push("/image-viewer", imageViewerParasm);
  };

  const _renderDonateImageItem = (item: Photo, index: number) => {
    return (
      <Image
        src={item.large?.url}
        className={styles.bannerImage}
        onClick={() => _handleClickImage(index)}
        key={item.large?.url}
      />
    );
  };

  const getDonateImages = (): Photo[] => {
    if (!donateTargetData) return [];
    let images: Photo[] = [];
    if (donateTargetData.photo) {
      images.push(donateTargetData.photo);
    }
    if (donateTargetData.medias && donateTargetData.medias.length > 0) {
      donateTargetData.medias.forEach((item) => {
        if (item.urls) {
          images.push(item.urls);
        }
      });
    }
    return images;
  };

  const _renderImages = () => {
    if (!donateTargetData) {
      return <div className={styles.bannerImage} />;
    }
    const images = getDonateImages();
    const numOfImages = images.length || 0;
    return (
      <div className="position-relative">
        {_renderImageIndicator()}
        <Slider
          {...{
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dotsClass: styles.bannerDot,
            arrows: false,
            aferChange: (index) => {
              console.log("After change", index);
              setActiveImageIndex(index);
            },
            onSwipe: (e) => {
              if (e === "left" && activeImageIndex < numOfImages - 1) {
                setActiveImageIndex(activeImageIndex + 1);
              }
              if (e === "right" && activeImageIndex > 0) {
                setActiveImageIndex(activeImageIndex - 1);
              }
            },
            // appendDots: () => {
            //   return (
            //     <div className="mt14">
            //       <CarouselIndicator
            //         length={numOfImages}
            //         activeIndex={activeImageIndex}
            //       />
            //     </div>
            //   );
            // },
          }}
        >
          {images?.map(_renderDonateImageItem)}
        </Slider>
      </div>
    );
  };

  const _renderImageIndicator = () => {
    if (!donateTargetData) {
      return <div />;
    }
    const images = getDonateImages();
    if (images.length <= 1) return <div />;
    return (
      <div className={styles.imageIndicator}>
        <span className="body12 textOnPrimary">
          {activeImageIndex + 1}/{images.length}
        </span>
      </div>
    );
  };

  const _handleViewProfile = () => {
    history.push(
      `/kol-profile?userId=${donateTargetData?.owner?.id}&donateTargetId=${donateTargetId}`
    );
  };

  const _renderSumary = () => {
    return (
      <div className="mb32">
        <div className="rowStart mb30">
          <div className="rowStart flex1">
            <Image src={targetImg} className={styles.targetMoneyImg} />
            <div>
              <div className="body14 textGray3 mb4">{t("money-target")}</div>
              <div className="subTitle14 textBlack2">
                {!!donateTargetData
                  ? Utils.formatMoney(donateTargetData.targetAmount)
                  : ""}
              </div>
            </div>
          </div>

          <div className="rowStart flex1">
            <Image src={remainTimeImg} className={styles.targetMoneyImg} />
            <div>
              <div className="body14 textGray3 mb4">{t("remain-time")}</div>
              <div className="subTitle14 textBlack2">
                {!!donateTargetData
                  ? Utils.getDonateTargetTimeRemainingText(donateTargetData, t)
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="mb8">
          <ProgressBar
            progress={Utils.getDonateProgress(donateTargetData)}
            finished={Utils.isFinishedTarget(donateTargetData)}
          />
        </div>
        <div className="rowStart mb24">
          <div className="flex1 body14 textBlack2">
            {donateTargetData?.actualAmount === 0 ||
            donateTargetData?.actualAmount === null ? (
              Utils.isFinishedTarget(donateTargetData) ? (
                t("no-donator")
              ) : (
                t("be-first-donator")
              )
            ) : (
              <>
                {t("raised-amount")}{" "}
                <span className="w600">
                  {Utils.formatMoney(donateTargetData?.actualAmount || 0)}{" "}
                  {t("vnd")}
                </span>
              </>
            )}
          </div>
          <div className="subTitle14 textBlack2">
            {!!donateTargetData
              ? Utils.getDonateProgressPercentageText(donateTargetData)
              : ""}
          </div>
        </div>
        <div className="mb24">
          <DonatorList
            donators={donateTargetData?.recentDonators}
            nbDonator={donateTargetData?.nbDonator}
          />
        </div>
        <div className="title18 textBlack2 mb14 breakWord">
          {donateTargetData?.name}
        </div>
        {!!donateTargetData && (
          <div className="body14 textGray3 mb8">{t("target-created-by")}:</div>
        )}
        {!!donateTargetData && (
          <div>
            <DonateTargetCreator
              onView={_handleViewProfile}
              data={donateTargetData?.owner}
            />
          </div>
        )}
      </div>
    );
  };

  const _hasRecentDonator = (): boolean => {
    return !(
      !listDonation ||
      !listDonation?.list ||
      listDonation?.list.length === 0
    );
  };

  const _hasDonateTargetSameAccount = (): boolean => {
    return !(!donateTargetSameAccount || donateTargetSameAccount.length === 0);
  };

  const _hasDonateButton = (): boolean => {
    return !Utils.isFinishedTarget(donateTargetData);
  };

  const _renderStory = () => {
    if (!donateTargetData) return <div />;
    return (
      <div
        className={
          !_hasDonateButton() &&
          !_hasRecentDonator() &&
          !_hasDonateTargetSameAccount()
            ? ""
            : "mb32"
        }
      >
        <div className="title16 textBlack2 mb14">{t("story")}</div>
        {/* <ShowMoreText
          lines={5}
          more={t("view-more")}
          less={t("collapse")}
          className="body14 textBlack2 prewrap"
          anchorClass="title14 textGray4 w500 viewMoreAnchor"
        >
          {donateTargetData?.description}
        </ShowMoreText> */}
        <ViewMoreText text={donateTargetData?.description} />
      </div>
    );
  };

  const _renderRecentDonationItem = (item: Donation, index: number) => {
    return <RecentDonationItem data={item} key={index} />;
  };

  const _handleLoadMoreRecentDonator = () => {
    if (
      loadingMoreDonation ||
      !listDonation ||
      !listDonation?.nextPageId ||
      listDonation?.list.length >= RECENT_DONATOR_MAX_RECORD
    ) {
      return;
    }
    _loadRecentDonation(
      listDonation?.nextPageId,
      RECENT_DONATOR_NEXT_PAGE_SIZE
    );
  };

  const _handleOpenApp = () => {
    Utils.openBrowser(config?.deepLinkMiniapp || THIENNGUYEN_APP_DEEPLINK);
  };

  const _handleOpenDonateTargetDetail = () => {
    Utils.openBrowser(`${config?.deepLinkMiniapp}/account-statement?bankAccountId=${donateTargetData?.payment?.bankAccount?.id}&username=${donateTargetData?.owner?.username}` || THIENNGUYEN_APP_DEEPLINK);
  };

  const _handleShowInfoRecentDonation = () => {
    UiService.openPopup({
      title: t("do-you-know"),
      children: (
        <>
          <div className="text-center body14 textBlack">
            <span>{t("donation-popup-info1-1")}</span>
            &nbsp;<span className="w700">{t("donation-popup-info1-2")}</span>
            &nbsp;
            <span>{t("donation-popup-info1-3")}</span>
          </div>
          <br />
          <div className="text-center body14 textBlack">
            {t("donation-popup-info2")}
          </div>
        </>
      ),
      onlyOkButton: true,
      okTitle: t("i-understand"),
      onOk: () => {
        UiService.closePopup();
      },
    });
  };

  const _renderRecentDonation = () => {
    if (!_hasRecentDonator()) {
      return <div />;
    }
    const showViewMoreBtn =
      !!listDonation?.nextPageId &&
      !!listDonation?.list &&
      listDonation?.list.length < RECENT_DONATOR_MAX_RECORD &&
      !!listDonation.count &&
      listDonation?.list.length < listDonation.count;
    const showDownloadApp =
      !!listDonation?.list &&
      listDonation?.list.length >= RECENT_DONATOR_MAX_RECORD &&
      !!listDonation.count &&
      listDonation?.list.length < listDonation.count;
    return (
      <div
        className={
          !_hasDonateButton() && !_hasDonateTargetSameAccount() ? "" : "mb32"
        }
      >
        <div className="title16 textBlack2 mb14">
          {t("recent-donate")}
          <Image
            src={infoPrimaryImg}
            className={styles.infoImg}
            onClick={_handleShowInfoRecentDonation}
          />
        </div>
        {listDonation?.list.map(_renderRecentDonationItem)}
        {!!loadingMoreDonation && <Loading containerClassName="columnCenter" />}
        {showViewMoreBtn && !loadingMoreDonation && (
          <div className="mt10">
            <Button
              variant="outline-primary"
              className="fullWidth"
              onClick={_handleLoadMoreRecentDonator}
            >
              {t("view-more")}
            </Button>
          </div>
        )}
        {showDownloadApp && !loadingMoreDonation && (
          <div className="rowCenter body14 textGray3 w500 mt14">
            <span>{t("view-all-donate-question")}</span>&nbsp;
            <span className="w600 textPrimary" onClick={_handleOpenApp}>
              {t("open-app-now")}
            </span>
          </div>
        )}
      </div>
    );
  };

  const _handleClickSameAccountItem = (target: DonateTarget) => {
    history.push(`/donate-target?id=${target.id}`);
  };

  const _renderDonateTargetSameAccountItem = (
    item: DonateTarget,
    index: number
  ) => {
    return (
      <div key={item.id}>
        <div className="rowCenter">
          <DonateTargetItem2
            data={item}
            onClick={_handleClickSameAccountItem}
          />
        </div>
      </div>
    );
  };

  const _renderDonateTargetSameAccount = () => {
    if (!_hasDonateTargetSameAccount()) {
      return <div />;
    }

    const numOfTarget = donateTargetSameAccount?.length || 0;
    return (
      <div className={!_hasDonateButton() ? "" : "mb32"}>
        <div className="ph16 title16 textBlack2 mb14">
          {t("same-raised-account")}
        </div>
        <div>
          {donateTargetSameAccount!.length > 1 ? (
            <Slider
              {...{
                className: "slider center",
                centerMode: true,
                dots: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "12px",
                infinite: true,
                dotsClass: "sliderDots",
                arrows: false,
                aferChange: (index) => {
                  console.log("After change", index);
                  setActiveDonateSliderIndex(index);
                },
                onSwipe: (e) => {
                  console.log("On swipe", e);
                  if (
                    e === "left" &&
                    activeDonateSliderIndex < numOfTarget - 1
                  ) {
                    setActiveDonateSliderIndex(activeDonateSliderIndex + 1);
                  }
                  if (e === "right" && activeDonateSliderIndex > 0) {
                    setActiveDonateSliderIndex(activeDonateSliderIndex - 1);
                  }
                },
                appendDots: () => {
                  return (
                    <div className="mt14">
                      <CarouselIndicator
                        length={numOfTarget}
                        activeIndex={activeDonateSliderIndex}
                      />
                    </div>
                  );
                },
              }}
            >
              {donateTargetSameAccount!.map(_renderDonateTargetSameAccountItem)}
            </Slider>
          ) : (
            <div className="ph16">
              {_renderDonateTargetSameAccountItem(
                donateTargetSameAccount![0],
                0
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const _handleDonate = () => {
    console.log("_handleDonate");
    history.push(
      `/donate?userId=${donateTargetData?.owner?.id}&donateTargetId=${donateTargetId}`
    );
  };

  return (
    <div className="screenContainer bgOnprimary">
      <BlurToolbar />
      <PullToRefresh
        onRefresh={_handleRefresh}
        refreshingContent={<Loading containerClassName="columnCenter" />}
        pullingContent=""
      >
        <>
          {_renderImages()}
          <div className={styles.content}>
            <div className="ph16">
              {_renderSumary()}
              <div className={"d-flex justify-content-end"} onClick={_handleOpenDonateTargetDetail}>
                <span style={{color: "#F54A00", fontSize: 14}}>{t('view-donate')}</span>
                <Image src={arrowRightPrimary} alt={"Arrow right primary"}/>
              </div>
              {_renderStory()}
              {_renderRecentDonation()}
            </div>
            {_renderDonateTargetSameAccount()}
            {_hasDonateButton() && (
              <div className={styles.bottomButtonSpacer} />
            )}
          </div>
        </>
      </PullToRefresh>
      {_hasDonateButton() && (
        <div className="bottomButtonBlockBlur">
          <Button
            variant="primary"
            className="fullWidth"
            onClick={_handleDonate}
          >
            {t("i-want-donate")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DonateTargetDetail;
